import { 
  START_ACTION,
  STOP_ACTION,
  UPLOAD_ACTIONS,
  ADD_TOAST,
  PROGRESS_ACTION,
  ERROR_ACTION
} from './types';
import { addToast } from './toast';
import { API_URL, appFetch } from '../helpers/fetch'
import { errors } from '../helpers/errors'
import moment from 'moment'
import { getBatches } from './vouchers';

export function startAction(data) {
  return (dispatch, getState) => { 
    const currentState = getState()
    dispatch({ type: PROGRESS_ACTION, actionId: data.actionPoint.uuid });
    appFetch("POST", API_URL, "/action/check-in", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: START_ACTION, actionId: data.actionPoint.uuid });
          dispatch(uploadActions())
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
          dispatch({ type: ERROR_ACTION });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
        dispatch({ type: ERROR_ACTION });
      })
  }
}

export function stopAction(data) {
  return (dispatch, getState) => { 
    const currentState = getState()
    dispatch({ type: PROGRESS_ACTION, actionId: data.actionPoint.uuid });
    appFetch("POST", API_URL, "/action/check-out", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: STOP_ACTION, actionId: data.actionPoint.uuid });
          dispatch(uploadActions())
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
          dispatch({ type: ERROR_ACTION });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
        dispatch({ type: ERROR_ACTION });
      })
  }
}

export function uploadActions(page) {
  return (dispatch, getState) => { 
    const currentState = getState()
    dispatch({ type: PROGRESS_ACTION });
    const data = {
      "actions": {
        since: moment().startOf('day').format('YYYY-MM-DD'),
        filters: {
          statuses: [
            { ident: 'started' }, 
            { ident: 'accepted' }
          ]
        }
      },
      "pagination": {
        "limit": 200,
        "offset": page*200 || currentState.actions.page*200 || 0
      }
    }
    appFetch("POST", API_URL, "/action/list", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: UPLOAD_ACTIONS, payload: result.data.actions });
          const activeAction = result.data.actions.map(e => ({event: e.event, status: e.status}))?.find(e =>  e.status.global.ident === "started") ?? false
          if(activeAction) {
            dispatch(getBatches(activeAction.event.uuid))
          }
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
          dispatch({ type: ERROR_ACTION });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
        dispatch({ type: ERROR_ACTION });
      })
  }
}

export function cancelAction(data) {
  return (dispatch, getState) => { 
    const currentState = getState()
    dispatch({ type: PROGRESS_ACTION, actionId: data.actionPoint.uuid });
    appFetch("POST", API_URL, "/action/cancel", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: START_ACTION, actionId: data.actionPoint.uuid });
          dispatch(uploadActions())
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
          dispatch({ type: ERROR_ACTION });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
        dispatch({ type: ERROR_ACTION });
      })
  }
}

export function sendPollStart(d) {
  return (dispatch, getState) => { 
    const currentState = getState()
    const data = {
      action: {
        uuid: d.id
      },
      poll: {
        ident: 'action_start',
        content: [
          {
            question_ident: 'q0',
            answer_ident: d.answer
          }
        ]
      }
    }
    appFetch("POST", API_URL, "/poll/answer", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {

      } else {

      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
        dispatch({ type: ERROR_ACTION });
      })
  }
}

export function sendPollEnd(d) {
  return (dispatch, getState) => { 
    const currentState = getState()
    const data = {
      action: {
        uuid: d.id
      },
      poll: {
        ident: 'action_end',
        content: [
          {
            question_ident: 'q0',
            answer_ident: [d.answer]
          }
        ]
      }
    }
    console.log(data)
    appFetch("POST", API_URL, "/poll/answer", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {

      } else {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
        dispatch({ type: ERROR_ACTION });
      })
  }
}