import { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { uploadAvailability, createAvailability, updateAvailability, deleteAvailability } from '../actions/reports'
import moment from 'moment';
import 'moment/locale/pl';
import Button from './Button';
import PopupModal from './PopupModal';
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { selectActions } from '../reducers/actions';
import { selectAvailability } from '../reducers/availability';


function Calendar() {
  const dispatch = useDispatch();
  const [availability, setAvailability] = useState([{ start: '09:00', end: '18:00' }]);
  const [hourStart, setHourStart] = useState(9);
  const [hourEnd, setHourEnd] = useState(16);
  const [selectedColumn, setSelectedColumn] = useState(0);
  const [selectedAvailability, setSelectedAvailability] = useState(null);
  const [popup, setPopup] = useState(false);
  const [updatePopup, setUpdatePopup] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(moment().format("Y-M"));
  const [dropdown, setDropdown] = useState(false);
  const actions = useSelector(selectActions);
  const availabilityList = useSelector(selectAvailability);

  moment().locale('pl');

  const hours = ['06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00']

  useEffect(()=>{
    dispatch(uploadAvailability());
  },[])

  const handleSetAvailability = () => {
    const since = selectedColumn.set("hour", hourStart).format("YYYY-MM-DD HH:mm:ss")
    const until = selectedColumn.set("hour", hourEnd).format("YYYY-MM-DD HH:mm:ss")

    const data = {
      "availability": {
        "since": since,
        "until": until
      }
    }

    dispatch(createAvailability(data))
  }

  const updateAvailability = () => {
    const since = moment(selectedAvailability.since.date).set("hour", hourStart).format("YYYY-MM-DD HH:mm:ss")
    const until = moment(selectedAvailability.until.date).set("hour", hourEnd).format("YYYY-MM-DD HH:mm:ss")

    const data = {
      "availability": {
        "since": since,
        "until": until,
        "uuid": selectedAvailability.uuid
      }
    }

    dispatch(updateAvailability(data))
  }

  const handleDeleteAvailability = () => {
    const data = {
      "availability": {
        "uuid": selectedAvailability.uuid
      }
    }

    dispatch(deleteAvailability(data))
  }

  const openPopup = (hour, date) => {
    setHourStart(moment('2020-10-10 ' + hour).format('H'));
    setHourEnd(moment('2020-10-10 ' + hour).add(1, 'hours').format('H'));
    setSelectedColumn(date);
    setPopup(true);
  }

  const openUpdatePopup = (avail) => {
    setHourStart(moment(avail.since.date).format('H'));
    setHourEnd(moment(avail.until.date).format('H'));
    setSelectedAvailability(avail);
    setUpdatePopup(true);
  }

  const currentDay = moment().format("D")
  const currentMonth = moment().format("Y-M")
  const currentMonthDays = moment().daysInMonth()
  const selectedMonthDays = selectedMonth !== currentMonth ? moment(selectedMonth + '-1').daysInMonth() : currentMonthDays
  let days = []
  let columns = []

  for (let i = currentMonth !== selectedMonth ? 1 : currentDay; i <= selectedMonthDays; i++) {
    const day = moment(selectedMonth + "-" + i, 'Y-M-D')

    const item = (
      <div key={i} className={i === currentDay ? "calendar-day today" : "calendar-day"}>
        <div className="day-name">{day.format("dd")}</div><div className="day-no">{day.format("D")}</div>
      </div>
    )
    days.push(item)
  }

  for (let i = currentMonth !== selectedMonth ? 1 : currentDay; i <= selectedMonthDays; i++) {
    const day = moment(selectedMonth + "-" + i, 'Y-M-D')
    const availabilityItems = _.filter(availabilityList.list, (item) => {
      if (item.since != null && moment(item.since.date).format("YYYY-MM-DD") === day.format("YYYY-MM-DD")) {
        return item
      }
    })

    const actionItems = _.filter(actions.list, (item) => {
      if (item.since != null && moment(item.since.date).format("YYYY-MM-DD") === day.format("YYYY-MM-DD")) {
        return item
      }
    })

    const item = (
      <div key={i} className="calendar-column">
        {hours.map((item, key) => {
          return <div key={'cell-' + key} className="hour-cell"
            onClick={() => { openPopup(item, day) }}></div>
        })}
        {availabilityItems.map((avail, k) => {
          const diff = moment(avail.since.date).diff(moment(avail.until.date), 'hours');
          return (
            <div className="availability"
              style={{ height: (-diff) * 40 - 3, top: (((moment(avail.since.date).format("H") - 6) * 40) - 3) + "px" }}
              onClick={() => { openUpdatePopup(avail) }}>
              <span>Dostępność</span>
            </div>
          )
        })}
        {actionItems.map((action, k) => {
          const diff = moment(action.since.date).diff(moment(action.until.date), 'hours');
          return <div className="event" style={{ height: (-diff) * 40 - 3, top: (((moment(action.since.date).format("H") - 6) * 40) - 3) + "px" }}>
            <span><Link to={`/akcja/${action.uuid}`}>{action.name}</Link></span>
          </div>
        })}
      </div>
    )
    columns.push(item)
  }

  return (
    <section className="calendar">
      <div className="calendar-top">
        <div className="month">
          <p className="selected" onClick={() => {
            setDropdown(!dropdown);
          }}>{moment(selectedMonth + '-1', 'YYYY-M-D').format("MMMM")}</p>
          <ul className={dropdown ? "month-dropdown open" : "month-dropdown"}>
            <li onClick={() => {
              setDropdown(false);
              setSelectedMonth(moment().format("Y-M"));
            }}>{moment().format("MMMM")}</li>
            <li onClick={() => {
              setDropdown(false);
              setSelectedMonth(moment().add(1, 'months').format("Y-M"));
            }}>{moment().add(1, 'months').format("MMMM")}</li>
            <li onClick={() => {
              setDropdown(false);
              setSelectedMonth(moment().add(2, 'months').format("Y-M"));
            }}>{moment().add(2, 'months').format("MMMM")}</li>
          </ul>
        </div>
      </div>
      <div className="calendar-hour-labels">
        {hours.map((item, key) => {
          return <div key={key} className="hour-label">{item}</div>
        })}
      </div>
      <div className="calendar-columns">
        <div className="calendar-header">
          {days}
        </div>
        <div className="calendar-columns-inner">
          {columns}
        </div>
      </div>
      <PopupModal open={popup} hidePopup={() => setPopup(false)}>
        <div className="popup-inner">
          <h3>Podaj zakres dostępności:</h3>
          <div className="hours-inputs">
            <label>od</label>
            <input type="number" min="6" max="22" onChange={(e) => setHourStart(e.target.value)} value={hourStart} />
            <label>do</label>
            <input type="number" min="6" max="22" onChange={(e) => setHourEnd(e.target.value)} value={hourEnd} />
          </div>
          <Button action={() => { setPopup(false); handleSetAvailability(); }}>Gotowe</Button>
        </div>
      </PopupModal>

      <PopupModal open={updatePopup} hidePopup={() => setPopup(false)}>
        <div className="popup-inner">
          <h3>Edytuj zakres dostępności:</h3>
          <div className="hours-inputs">
            <label>od</label>
            <input type="number" min="6" max="22" onChange={(e) => setHourStart(e.target.value)} value={hourStart} />
            <label>do</label>
            <input type="number" min="6" max="22" onChange={(e) => setHourEnd(e.target.value)} value={hourEnd} />
          </div>
          <Button action={() => { setPopup(false); handleSetAvailability(); }}>Zapisz</Button>
          <Button type="close" action={() => { setUpdatePopup(false); handleDeleteAvailability(); }}>Usuń</Button>
        </div>
      </PopupModal>
    </section>
  );
}

export default Calendar;