export const loadState = () => {
	try {
		const serializedState = localStorage.getItem('state');

		if (serializedState === null) {
			return { 
				actions: {
					list: [],
					currentAction: 0
				},
				reports: {
					list: []
				},
				vouchers: {
					batches: [],
					vouchers: []
				},
				mgm: {
					ranking: {
						balance: 0,
						position: 0
					},
					status: 'null',
					consents: {
						mobileOptIn: false,
						hypercareOptIn: false,
						socialMediaOptIn: false,
						newsletterCommercialAuthorization: false
					}
				}
			}
		}
		return JSON.parse(serializedState);
	} catch (err) {
		return { 
			actions: {
					list: [],
					currentAction: 0
				},
			reports: {
				list: []
			},
			vouchers: {
				batches: [],
				vouchers: []
			},
			mgm: {
				ranking: {
					balance: 0,
					position: 0
				},
				status: 'null',
				consents: {
					mobileOptIn: false,
					hypercareOptIn: false,
					socialMediaOptIn: false,
					newsletterCommercialAuthorization: false
				}
			}
		}
	}
}

export const saveState = (state) => {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem('state', serializedState);
	} catch (err) {
		// errors
	}
}

export const clearState = () => {
	try {
		//console.log(localStorage.getItem('state'))
		localStorage.removeItem('state');
		//console.log(localStorage.getItem('state'))
		console.log('clear state')
	} catch (err) {
		// errors
		console.log(err)
	}
}