import React from 'react'

export default function FixedButton({ children, ...rest }) {

  return (
    <div className='fixed-button-wrapper'>
      <div className='flex-wrapper'>
        {React.Children.map(children, child => {
          return typeof (child?.type) === "function"
            ? React.createElement(child.type, {
              ...{
                ...rest,
                ...child.props
              }
            })
            : child;
        })}
      </div>
    </div>
  )
}
