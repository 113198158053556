import React, { Component } from 'react';

class SingleToast extends Component {

  constructor(props) {
    super(props)
  }

  componentWillMount() {
   
  }

  componentDidMount() {
    setTimeout(()=>{
      this.props.remove();
    }, 8000)
  }

  render() {

    return (
      <div className={"single-toast "+this.props.type+"-toast"} onClick={ () => { this.props.remove() } }>
        <span className="toast-text">{ this.props.message }</span>
      </div>
    )
  }
}

export default SingleToast