import { 
    LOGIN,
    LOGOUT,
    REMIND,
    REMIND_OTP,
    SESSION,
    CHANGE_PASS,
    ADD_TOAST
  } from './types';
import { API_URL, appFetch } from '../helpers/fetch'
import { errors } from '../helpers/errors'
import { clearState } from '../localStorage';
import { uploadActions } from './actions'
  
export function login(data, navigate) {
  return (dispatch, getState) => { 
      appFetch("POST", API_URL, "/auth/login", data, false, (result) => {
          if(result.status && result.status.success) {
              dispatch({ type: LOGIN, payload: { token: result.data.token, user: result.data.user } });
              dispatch(uploadActions())
              navigate("/akcje")
          } else {
              dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
          }
      }, (error) => {
         dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function ssoLogin(data, history) {
  return (dispatch, getState) => { 
      appFetch("POST", API_URL, "/auth/sso-login", {}, false, (result) => {
          if(result.status && result.status.success) {
            console.log(result.data.url)
            window.location.replace(result.data.url)
          } else {
              dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
          }
      }, (error) => {
         dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function redirectLogin(token, navigate) {
  return (dispatch, getState) => { 
    dispatch({ type: LOGIN, payload: { token: token, user: {} } });
    dispatch(checkSession(navigate))
  }
}

export function checkSession(navigate) {
  return (dispatch, getState) => { 
    const currentState = getState()
      appFetch("POST", API_URL, "/auth/session", false, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
          if(result.status && result.status.success) {
              dispatch({ type: SESSION, payload: { token: result.data.token, user: result.data.user } });
              dispatch(uploadActions())
              if (!process.env.REACT_APP_BRANCH === 'dev') {
                navigate("/akcje")
              }
          } else {
              dispatch({ type: SESSION, payload: { token: null, user: null } });
              if(currentState.user.token && currentState.user.token.uuid) dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
              navigate("/")
          }
      }, (error) => {
         dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}
  
export function logout(navigate) {
  return (dispatch, getState) => { 
    const currentState = getState()
      appFetch("POST", API_URL, "/auth/logout", false, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
          if(result.status && result.status.success) {
            dispatch({ type: LOGOUT, payload: null });
            navigate('/',{replace: true})
          } else {
            dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
            navigate('/',{replace: true})
          }
      }, (error) => {
         dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function remind(data, navigate) {
  return (dispatch, getState) => { 
    const currentState = getState()
    appFetch("POST", API_URL, "/auth/reset-token", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: REMIND, payload: null });
          navigate("/remind/code")
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
          navigate("/remind")
      }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function changePassWD(data) {
  return (dispatch, getState) => { 
    const currentState = getState()
    appFetch("POST", API_URL, "/auth/update-password", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: CHANGE_PASS, payload: null });
          dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Zmieniono hasło." } });
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
      }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function remindCode(data, navigate) {
  return (dispatch, getState) => { 
    const currentState = getState()
    appFetch("POST", API_URL, "/auth/reset-password", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: REMIND_OTP, payload: null });
          dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Pomyślnie zmieniono hasło!" } });
          navigate("/")
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
          navigate("/remind")
      }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}
