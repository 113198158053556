import React, { useRef } from 'react'
import Receipt from "../../../img/receipt.svg"
import CameraImg from "../../../img/camera.svg"
import Done from "../../../img/done.svg"
import { useWebformStore } from './store'
import CameraModal from './CameraModal'
import NiceModal from '@ebay/nice-modal-react'

export default function Reciept() {
  const photoRef = useRef(null);
  const recieptURL = useWebformStore(state => state.recieptURL)

  return (
    <>
      <div className="img-container">
        <div className="left">
          <canvas ref={photoRef} />

          <img src={recieptURL ? recieptURL : Receipt} alt='' className={recieptURL ? "final-img" : "receipt"} />
        </div>
        <button className="right" onClick={e => { NiceModal.show("camera-modal"); e.preventDefault() }}>
          <img src={recieptURL ? Done : CameraImg} alt="" />
          {recieptURL ?
            <p>Paragon<br />dodany</p>
            :
            <p>Zrób zdjęcie<br />paragonu</p>
          }
        </button>
      </div>
      <CameraModal id="camera-modal" photoRef={photoRef} />
    </>
  )
}
