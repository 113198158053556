import { useEffect, useState } from 'react';
import { mgmJoin, mgmValidate, mgmClear, mgmShowForm, mgmInvite, mgmUpdate, setMobileOptIn, setCommercialOptIn, setSocialOptIn, setHypercareOptIn } from '../actions/mgm'
import { NavLink } from 'react-router-dom';
import _ from 'lodash'
import moment from 'moment';
import Button from './Button';
import { useDispatch, useSelector } from 'react-redux';
import { selectActions } from '../reducers/actions';
import { selectMGM } from '../reducers/mgm';

function MGMPage() {
  const dispatch = useDispatch();
  const actions = useSelector(selectActions);
  const [userEmail, setUserEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [smsConsent, setSmsConsent] = useState(false);
  const [phoneConsent, setPhoneConsent] = useState(false);
  const [socialConsent, setSocialConsent] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [consentError, setConsentError] = useState(false);
  const [status, setStatus] = useState(false);
  const mgm = useSelector(selectMGM);

  useEffect(()=>{
    dispatch(mgmJoin());
    setStatus(checkStatus())
  },[])

  const checkStatus = () => {
    let status = false;
    actions.list.forEach(action => {
      if ( action.status.user.status === 'started') {
        status = true;
      }
    });
    return status;
  }

  const checkUser = () => {
    if (emailValid()) {
      dispatch(mgmValidate(userEmail));
    }
  }

  const emailValid = () => {
    if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(userEmail )) {
      setEmailError(true);
      return false;
    } else {
      setEmailError(false);
      return true;
    }
  }

  const checkConsent = () => {
    if (mgm.consents.newsletterCommercialAuthorization) {
      setConsentError(false);
      return true;
    } else {
      setConsentError(true);
      return false;
    }
  }

  const clear = () => {
    dispatch(mgmClear());
    setUserEmail('');
  }

  const content = () => {
    switch(mgm.status) {
      case null:
        if (status) { 
          return (
            <div className="mgm-user-add">
              <h2>Dodaj użytkownika do MGM</h2>
              <p>Sprawdź status użytkownika w SalesForce</p>
              <input type="email" value={userEmail} onChange={(e)=> setUserEmail(e.target.value)}/>
              {emailError ? <p>Nieprawidłowy adres email</p> : null}
              <div className="center">
                <Button className="btn" action={ () => { checkUser() } }>Sprawdź</Button>
              </div>
            </div>
          )
        } else {
          return <div></div>
        }
      case 'opt-in-request':
        return (
          <div className="mgm-modal-box">
            <h3>Podany użytkownik nie wyraził wszystkich zgód marketingowych</h3>
            <p>Poproś użytkownika, by potwierdził wyrażenie zgód marketingowych.</p>
            <div className="buttons">
              <Button className="btn white" action={ ()=>{ clear() }} >Anuluj</Button>
              <Button className="btn" action={ ()=>{ dispatch(mgmShowForm()) }} >Potwierdź</Button>
            </div>
          </div>
        )
      case 'opt-in-form':
        let checkboxError = '';
        if (consentError) {
          checkboxError = 'field-error'
        }
        return (
          <div className="mgm-modal-box">
            <h3>Zgody marketingowe</h3>
            <p className="form-small">Wyrażam zgodę na przetwarzanie podanych przeze mnie danych osobowych przez BAT oraz CHIC, jako współadministratorów, w celu prowadzenia przez te podmioty niepublicznych działań o charakterze marketingu bezpośredniego, obejmujących prezentowanie mi informacji o produktach i usługach oferowanych przez grupę BAT oraz o konkursach i innych inicjatywach grupy BAT:</p>

            <div className={"checkbox-item "+checkboxError}>
              <input type="checkbox" id="sms_consent" name="sms_consent" checked={ mgm.consents.newsletterCommercialAuthorization } onChange={ (e) => { dispatch(setCommercialOptIn(e.target.checked)) } }/>
              <label htmlFor="sms_consent">
              za pośrednictwem środków komunikacji elektronicznej, tj.: poczty elektronicznej (e-mail) oraz wiadomości tekstowych SMS *
              </label>
            </div>
            <div className={"checkbox-item"}>
              <input type="checkbox" id="phone_consent" name="phone_consent" checked={ mgm.consents.mobileOptIn } onChange={ (e) => { dispatch(setMobileOptIn(e.target.checked)) } }/>
              <label htmlFor="phone_consent">
              poprzez kontakt telefoniczny, w tym połączenia głosowe 
              </label>
            </div>
            <div className={"checkbox-item"}>
              <input type="checkbox" id="social_consent" name="social_consent" checked={ mgm.consents.socialMediaOptIn } onChange={ (e) => { dispatch(setSocialOptIn(e.target.checked)) } }/>
              <label htmlFor="social_consent">
              w sekcjach reklamowych na portalach społecznościowych (np. Facebook, Instagram i podobne) 
              </label>
            </div>
            <div className="checkbox-item">
              <input type="checkbox" id="marketing_consent" name="marketing_consent" checked={ mgm.consents.hypercareOptIn } onChange={ (e) => { dispatch(setHypercareOptIn(e.target.checked)) } }/>
              <label htmlFor="marketing_consent">
              Wyrażam zgodę na przetwarzanie przez BAT oraz CHIC, jako 
              współadministratorów, moich danych osobowych wskazanych powyżej 
              oraz dodatkowych informacji przekazywanych przeze mnie 
              w ramach ankiet nt. użytkowania produktów oferowanych przez grupę BAT oraz w celu objęcia mnie programem wsparcia posprzedażowego, co wiąże się m.in. 
              z udziałem w badaniach opinii satysfakcji Klienta przeprowadzanych w ramach rozmów telefonicznych oraz za pośrednictwem innych środków komunikacji elektronicznej, w tym 
              na stronach internetowych BAT 
              i CHIC, których jestem zarejestrowanym użytkownikiem.
              </label>
            </div>

            <p className="form-small"><strong>*Zgoda wymagana</strong></p>

            <p className="form-small">
              Pamiętaj, że zawsze możesz wycofać powyższe zgody na przetwarzanie Twoich danych osobowych. Wycofanie zgody nie będzie miało jednak wpływu na zgodność z prawem naszych działań podjętych jeszcze przed jej wycofaniem. Aby wycofać swoją zgodę skontaktuj się z nami pod adresem: daneosobowe@bat.com.pl
            </p >

            <p className="form-small">
              Współadministratorami danych osobowych,
              o których mowa w powyższym formularzu
              są British American Tobacco Polska Trading
              sp. z o.o. z siedzibą w Warszawie (BAT)
              oraz CHIC spółka z ograniczoną odpowiedzialnością sp. k. z siedzibą
              w Ostrzeszowie (CHIC). Twoje dane będą przewarzane wyłącznie w tych celach,
              na które wyraziłeś/aś swoją zgodę powyżej.
            </p>

            <p className="form-small">
              Możesz się z nami skontaktować pisząc na adres poczty elektronicznej daneosobowe@bat.com.pl lub listownie na adres British American Tobacco Polska Trading Spółka z o.o., ul. Krakowiaków 48, 02-255 Warszawa.
            </p>

            <p className="form-small">
              W związku z podaniem danych osobowych przysługuje Ci prawo do uzyskania dostępu do treści Twoich danych, prawo ich poprawiania, prawo do ich przenoszenia, prawo do żądania ich usunięcia oraz prawo ograniczenia ich przetwarzania. Jeśli uznasz, że przetwarzamy Twoje dane osobowe niezgodnie z prawem, możesz wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych.
            </p>

            <p className="form-small">Więcej informacji o ochronie Twoich danych osobowych oraz o zasadach wspólnego nimi administrowania przez BAT oraz CHIC znajdziesz w naszej Polityce Prywatności.</p>

            {consentError ? <p className="error-msg"><strong>Zaznacz wymagane zgody</strong></p> : null}
            <div className="buttons">
              <Button className="btn white" action={ ()=>{ clear() }} >Anuluj</Button>
              <Button className="btn" action={ ()=>{ if (checkConsent()) dispatch(mgmUpdate()) }} >Potwierdź</Button>
            </div>
          </div>
        )
      case 'can-invite':
        return (
          <div className="mgm-modal-box">
            <h3>Podany użytkownik jest w bazie SalesForce</h3>
            <p>Czy potwierdzasz wysłanie zaproszenia?</p>
            <div className="buttons">
              <Button className="btn white" action={ ()=>{ clear() }} >Anuluj</Button>
              <Button className="btn" action={ ()=>{ dispatch(mgmInvite()) }} >Potwierdź</Button>
            </div>
          </div>
        )
      case 'not-found':
        return (
          <div className="mgm-modal-box">
            <h3>Przykro nam, nie ma takiego użytkownika</h3>
            <div className="center">
              <Button className="btn" action={ ()=>{ clear() }} >Zamknij</Button>
            </div>
          </div>
        )
      case 'finished':
        return (
          <div className="mgm-modal-box">
            <h3>Dziękujemy za potwierdzenie danych</h3>
            <p>SMS z linkiem do programu MGM zostanie wysłany na podany numer telefonu.</p>
            <div className="center">
              <Button className="btn" action={ ()=>{ clear() }} >Zamknij</Button>
            </div>
          </div>
        )
      case 'error':
        return (
          <div className="mgm-modal-box">
            <h3>Ups!</h3>
            <p>Coś poszło nie tak...</p>
            <div className="center">
              <Button className="btn" action={ ()=>{ clear() }} >Zamknij</Button>
            </div>
          </div>
        )
      case 'processing':
        return (
          <div className="mgm-modal-box">
            <svg className="preloader" viewBox="0 0 90 90">
              <circle id="c" fill="none" stroke-width="4" stroke-linecap="round" stroke="red" cx="45" cy="45" r="43" />
            </svg>
          </div>
        )
      case 'already-member':
        return (
          <div className="mgm-modal-box">
            <h3>Użytkownik jest już w Strefie Wyboru. Nie możemy wysłać zaproszenia.</h3>
            <div className="center">
              <Button className="btn" action={ ()=>{ clear() }} >Zamknij</Button>
            </div>
          </div>
        )
      case 'no-products':
        return (
          <div className="mgm-modal-box">
            <h3>Użytkownik nie ma zarejestrowanych urządzeń. Nie możemy wysłać zaproszenia.</h3>
            <div className="center">
              <Button className="btn" action={ ()=>{ clear() }} >Zamknij</Button>
            </div>
          </div>
        )
      case 'no-consents':
        return (
          <div className="mgm-modal-box">
            <h3>Podany użytkownik nie wyraził wszystkich zgód marketingowych</h3>
            <p>Poproś użytkownika, by potwierdził wyrażenie zgód marketingowych.</p>
            <div className="buttons">
              <Button className="btn white" action={ ()=>{ clear() }} >Anuluj</Button>
              <Button className="btn" action={ ()=>{ dispatch(mgmShowForm()) }} >Potwierdź</Button>
            </div>
          </div>
        )
      default:
        return '';
    }
  }


  return (
    <section className="mgm">
      <h2 className="title">MGM</h2>
      <div className="mgm-account">
        <div className="points">
          <div className="user-points">{ mgm.ranking?.balance }</div>
          <div>Twoje punkty</div>
        </div>
        <div className="ranking">
          <div className="user-placement">{ mgm.ranking?.position }</div>
          <div>Twoje miejsce w rankingu</div>
        </div>
      </div>
      { content() } 
    </section>
  );
  
}

export default MGMPage;