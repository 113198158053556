import { ADD_TOAST, REMOVE_TOAST } from "./types";

export function addToast(options = {}) {
  return {
    type: ADD_TOAST,
    payload: createToast(options)
  };
}

export function removeToast(id) {
  return {
    type: REMOVE_TOAST,
    payload: id
  };
}

let id = 0;

const defaultOptions = {
  color: "#6796e6"
};

function createToast(options) {
  return {
    ...defaultOptions,
    ...options,
    id: id++
  }
}