import React from 'react'
import Modal from './Modal';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

export default NiceModal.create(({ error }) => {
  const modal = useModal();

  return (
    <Modal open={modal.visible} hidePopup={modal.hide} onAfterClose={modal.remove}>
      <div className="default-popup">
        <h3>
          Dziękujemy
        </h3>
        <p>Twoje zdjęcia i odpowiedzi zostały zapisane.</p>
        <button onClick={() => { modal.hide(); modal.remove() }} className={`btn btn-dark`}>
          Zamknij
        </button>
      </div>
    </Modal>
  )
});
