import {
  UPLOAD_AVAILABILITY
} from "../actions/types";
import _ from "lodash";

export default function(state = {}, action) {
  switch (action.type) {

    case UPLOAD_AVAILABILITY:
      return {
        ...state,
        list: action.payload,
      }

    default:
      return state;
  }
}

export const selectAvailability = (state) => state.availability;