import { useWebformStore } from "./store";
import CurrentStep from "./CurrentStep"
import WebfromStep from "../../../img/webfrom-step.svg";
import FixedButton from "./FixedButton";
import { ReactComponent as Reject } from "../../../img/reject.svg"
import { offersDetails } from "./data";


export const SuccessPage = () => {
	const resetForm = useWebformStore(state => state.resetForm)
	const { icConsentStatus, icError } = useWebformStore(state => state.webformState)
	const { selectedOffers } = useWebformStore(state => state.webformData)
	const offerBrands = Object.keys(selectedOffers).filter(e => e !== "InspirationClub")


	return (
		<section className="form-section">
			<CurrentStep />
			<div className="success-page">
				<div className="success-box">
					{offerBrands.map((offerName, index) => <div className="row" key={index}>
						<div className="icon">
							<img src={WebfromStep} alt="" />
						</div>
						<p><strong>{offersDetails.find(e => e.offer === offerName).successText}</strong></p>
					</div>)}
					{icConsentStatus && (typeof icConsentStatus === "string" ?
						<div className="row error">
							<div className="icon">
								<Reject />
							</div>
							<p><strong>{ icError }</strong><br />Kod błędu: {icConsentStatus}</p>
						</div>
						:
						<div className="row">
							<div className="icon">
								<img src={WebfromStep} alt="" />
							</div>
							<p><strong>Poinformuj klienta, że na jego adres e-mail została wysłana wiadomość z linkiem aktywacyjnym do programu lojalnościowego Inspiration Club.</strong></p>
						</div>)}
				</div>
				<FixedButton>
					<button onClick={() => { resetForm() }} className={`btn small-btn`}>
						Zakończ
					</button>
				</FixedButton>

				{/* <div className="container">
					<h2 className={`first show`}>
						<span>Dziękujemy za dołączenie do grona użytkowników glo™/Vuse/Velo.</span>
					</h2>
					<p className={`second center show`}>
						<span>
							Od teraz możemy dzielić się z Tobą spersonalizowanymi informacjami o nowych ofertach
							specjalnych, a także ciekawostkami związanymi z Twoim urządzeniem.
						</span>
					</p>
					<h2 className={`third show`}>
						<span>Do usłyszenia! </span>
					</h2>
					<button onClick={() => {resetForm()}} className={`btn small-btn empty-btn`}>
						Wróć
					</button>
				</div> */}
			</div>
		</section>
	);
};
