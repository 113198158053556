import React, { useEffect } from "react";
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Modal from "../Modal";
import { useWebformStore } from "./store";
import GloLogo from "../../../img/glo-logo.svg"
import VeloLogo from "../../../img/velo-logo.jpg"
import VuseLogo from "../../../img/vuse-logo.jpg"
import NeoLogo from "../../../img/neo-logo.jpg"

export default NiceModal.create(() => {
  const modal = useModal();
  const { data } = useWebformStore(state => state.webformState)

  return (
    <Modal open={modal.visible} hidePopup={modal.hide} onAfterClose={modal.remove}>
      <div className="availability-popup">
        <div className="content">
          <div className="element"><img src={GloLogo} alt="" /></div>
          <h3>Current Franchises</h3>
        </div>

        {/* <div className="upper">
          <h4>Lista ofert</h4>
          <button onClick={modal.hide}>&#x2715;</button>
        </div> */}
        {/* {data.Glo_Pro && <div className="element">Current Franchises</div>} */}
        {/* {data.newUser && <p> Konto podanego użytkownika nie istnieje</p>}        
        {Object.values(data).some(e => e === true) && <div className="active">
          <p>Aktywne oferty:</p>
          {data?.Glo && <div className="element"><img src={GloLogo} alt="" /></div>}
          {data?.Vuse && <div className="element"><img src={VuseLogo} alt="" /></div>}
          {data?.Velo && <div className="element"><img src={VeloLogo} alt="" /></div>}
          {data?.Neo && <div className="element"><img src={NeoLogo} alt="" /></div>}
          {data?.Neo && <div className="element"><img src={NeoLogo} alt="" /></div>}
        </div>} */}
        {/* {Object.values(data).some(e => e === false) && <div className="inactive">
          <p>Nieaktywne oferty:</p>
          {!data?.Glo && <div className="element"><img src={GloLogo} alt="" /></div>}
          {!data?.Vuse && <div className="element"><img src={VuseLogo} alt="" /></div>}
          {!data?.Velo && <div className="element"><img src={VeloLogo} alt="" /></div>}
          {!data?.Neo && <div className="element"><img src={NeoLogo} alt="" /></div>}
        </div>} */}
        <button className="btn" onClick={() => { modal.hide(); }}>OK</button>
      </div>
    </Modal>
  )
})
