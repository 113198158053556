import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getLocation } from "../components/Helpers";
import { sendPollEnd, sendPollStart, startAction, stopAction } from '../actions/actions';
import { mgmClear } from '../actions/mgm';
import { NavLink } from 'react-router-dom';
import Button from './Button';
import PopupModal from './PopupModal';
import moment from 'moment';
import { addToast } from '../actions/toast';
import { selectActions } from '../reducers/actions';

function ActionsPage() {
  const dispatch = useDispatch()
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [actionPointID, setActionPointID]= useState(-1);
  const [actionID, setActionID]= useState(-1);
  const [actionStatus, setActionStatus] = useState(null);
  const actions = useSelector(selectActions);
  const [startPollAnswer, setStartPollAnswer] = useState([false,false,false,false])
  const [endPollAnswer, setEndPollAnswer] = useState({id: 0, name: ''})
  const [userText, setUserText] = useState('');
  const [actionEndTime, setActionEndTime] = useState('')

  useEffect(()=>{
    dispatch(mgmClear());
  },[])

  const toggleAction = (status, id, action) => {
    if (moment(action.since.date).format('Y-M-D') === moment().format('Y-M-D')) {
      setActionID(action.uuid)
      setConfirmPopup(true)
      setActionPointID(id);
      setActionStatus(status);
      setActionEndTime(action.until.date);
    } else {
      dispatch(addToast({ type: "error", message: "Jeszcze nie można rozpocząć tej akcji" }));
    }
  }

  const toggleActionProcess = async (status, id) => {
    if (status) {
      const data = {
        "actionPoint": {
          "uuid": id
        }
      }

      dispatch(stopAction(data));
    } else {
      let data = {}
      
      try {
        const position = await getLocation()
        const { coords } = position
        console.log(coords)
  
        data = {
          "actionPoint": {
            "uuid": id,
          },
          "location": {
            "latitude": coords.latitude,
            "longitude": coords.longitude
          }
        }
      } catch (error) {
        data = {
          "actionPoint": {
            "uuid": id,
          },
          "location": {
            "latitude": null,
            "longitude": null
          }
        }
      }
      
      dispatch(startAction(data));
    }
  }

  const pollQuestions = [
    'Posiadam identyfikator',
    'Posiadam tablet',
    'Jestem ubrana/-y w wymagany strój',
    'Posiadam zapas produktu'
  ]

  const pollEndQuestions = [
    'Brak produktu w POP',
    'Brak produkty w moim zapasie',
    "Brak traffic'u w POP",
    'Brak sprzedaży',
    'Zmiana miejsca akcji',
    'Prywatne',
    'Inne'
  ]

  return (
    <section className="actions">
      <h2 className="title">Aktualnie trwające akcje:</h2>
      {actions.list.map((item, key) => {
        const cl = item.status.user.status === "started" ? 'active' : "";
        const canceled = item.status.global.ident === "hst_cancelled" ? 'cancelled' : "";

        return (
          <div key={'action-' + key} className={`action-item ${cl} ${canceled}`}>
            <NavLink to={"/akcja/" + item.uuid} className="action-name">
              {item.since != null && item.until != null ? <span className="date">{moment(item.since.date, 'YYYY-MM-DD').format('D-M-Y') + ", " + moment(item.since.date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') + ' - ' + moment(item.until.date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}</span> : ''}
              {item.name}
            </NavLink>
            {(item.actionPoints.length >= 1 || item.status.user.status === "started") && item.status.global.ident !== "hst_cancelled" ? <div className="start-stop" style={actions.progress ? { opacity: "0.4" } : {}} onClick={() => { if (!actions.progress) toggleAction(item.status.user.status === "started" ? true : false, item.actionPoints[0].uuid, item) }}></div> : null}
          </div>
        )

      })
      }
      <PopupModal open={confirmPopup} hidePopup={() => setConfirmPopup(false)}>
        <div className="popup-inner">
          <h3>{actionStatus ? "Zakończyć akcję?" : "Rozpocząć akcję?"}</h3>
          { actionStatus ?
              <div className="poll-end">
              { pollEndQuestions.map((item, key)=>{
                return (
                  <div className="checkbox-wrapper poll-checkbox">
                    <label htmlFor={`endpoll${key}`}>
                      <input id={`endpoll${key}`} type="radio" name="endpoll" className='checkbox' onChange={()=>{
                        setEndPollAnswer({id: key, name: item});
                      }} />
                      <span>{ item }</span>
                    </label>
                  </div>
                )
              })}
              {endPollAnswer.id === 5 && <textarea onChange={(e)=>{setUserText(e.target.value)}}></textarea>}
            </div>
            :
            <div className="poll-start">
              { pollQuestions.map((item, key)=>{
                return (
                  <div className="checkbox-wrapper poll-checkbox">
                    <label htmlFor={`startpoll${key}`}>
                      <input id={`startpoll${key}`} type="checkbox" className='checkbox' onChange={()=>{
                        let updatedAnswer = [...startPollAnswer]
                        updatedAnswer[key] = !updatedAnswer[key]
                        setStartPollAnswer(updatedAnswer)
                      }} />
                      <span>{ item }</span>
                    </label>
                  </div>
                )
              })}
            </div>
          }
          <div className="buttons">
            <Button type="empty" action={() => setConfirmPopup(false) }>Anuluj</Button>
            <Button style={actions.progress ? { opacity: "0.4" } : {}} action={
              () => { 
                setConfirmPopup(false); 
                if (actionStatus) {
                  dispatch(sendPollEnd({answer: endPollAnswer.id < 5 ? endPollAnswer.name : userText, id: actionID}))
                } else {
                  dispatch(sendPollStart({answer: startPollAnswer, id: actionID}))
                }
                if (!actions.progress) toggleActionProcess(actionStatus, actionPointID)
              }}>Potwierdź</Button>
          </div>
        </div>
      </PopupModal>
    </section>
  );
}

export default ActionsPage;