import { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { startAction, stopAction, sendPollStart, sendPollEnd, cancelAction } from '../actions/actions';
import { redeemVoucher, requestVouchers, getBatches, getVouchers, selectBatch } from '../actions/vouchers';
import { addToast } from '../actions/toast';
import _ from 'lodash';
import BottomModal from './BottomModal';
import Button from './Button';
import LocationView from './LocationView';
import PopupModal from './PopupModal';
import { ReactComponent as Arrow } from '../../img/arrow-back.svg';
import { getLocation } from "../components/Helpers";
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { selectActions } from '../reducers/actions';
import { selectVouchers } from '../reducers/vouchers';

function SingleActionsPage() {
  const dispatch = useDispatch();
  const actions = useSelector(selectActions);
  const vouchers = useSelector(selectVouchers);
  const { actionId } = useParams();
  const [actionPointID, setActionPointID]= useState(-1);
  const action = _.filter(actions.list, { uuid: actionId })[0];
  const [codesPopup, setCodesPopup] = useState(false);
  const [popup, setPopup] = useState(false);
  const [locationKey, setLocationKey] = useState(_.findIndex(action?.actionPoints, (o) => { return o.uuid == action.status.user.actionPoint.uuid; }));
  const [modal, setModal] = useState('codes');
  const [selectedItem, setSelectedItem] = useState(0);
  const [selectedActionId, setSelectedActionId] = useState(0);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [batchesPopup, setBatchesPopup] = useState(false);
  const [confirmStartPopup, setConfirmStartPopup] = useState(false);
  const [cancelPopup, setCancelPopup] = useState(false);
  const [startPollAnswer, setStartPollAnswer] = useState([false,false,false,false])
  const [endPollAnswer, setEndPollAnswer] = useState({id: 0, name: ''})
  const [userText, setUserText] = useState('');

  useEffect(() => {
    dispatch(getBatches(action?.event.uuid))
  },[])

  useEffect(() => {
    /*if (this.action !== _.filter(nextProps.actions.list, { uuid: this.actionId })[0]) {
      this.action = _.filter(nextProps.actions.list, { uuid: this.actionId })[0];
      this.locationKey = _.findIndex(this.action.actionPoints, (o) => { return o.uuid == this.action.status.user.actionPoint.uuid; });
      this.setState({ location: this.locationKey })
    }*/
  },[])


  const toggleAction = (status) => {
    if (moment(action.since.date, 'YYYY-MM-DD').format('Y-M-D') === moment().format('Y-M-D')) {
      setConfirmPopup(true);
    } else {
      dispatch(addToast({ type: "error", message: "Jeszcze nie można rozpocząć tej akcji" }));
    }
  }

  const toggleActionProcess = async (status, id) => {
    if (status) {
      const data = {
        "actionPoint": {
          "uuid": action.actionPoints[0] ? action.actionPoints[0].uuid : null
        }
      }
      dispatch(stopAction(data));
    } else {
      let data = {}
      
      try {
        const position = await getLocation()
        const { coords } = position
  
        data = {
          "actionPoint": {
            "uuid": action.actionPoints[0] ? action.actionPoints[0].uuid : null,
          },
          "location": {
            "latitude": coords.latitude,
            "longitude": coords.longitude
          }
        }
      } catch (error) {
        data = {
          "actionPoint": {
            "uuid": action.actionPoints[0] ? action.actionPoints[0].uuid : null,
          },
          "location": {
            "latitude": null,
            "longitude": null
          }
        }
      }

      dispatch(startAction(data));
    }
  }

  const dropAction = () => {
    const data = {
      "actionPoint": {
        "uuid": action.actionPoints[0] ? action.actionPoints[0].uuid : null
      }
    }
    dispatch(cancelAction(data));
  }

  const openRedeemPopup = (action, item) => {
    if (!item.redeemed) {
      setSelectedActionId(action.uuid);
      setSelectedItem(item);
      setCodesPopup(true);
    }
  }

  const requestCodes = () => {
    const data = {
      "action": {
        "uuid": action.uuid
      }
    }

    dispatch(requestCodes(data));
  }

  let emptyVouchers = false;
  let availableVouchers = [];
  if (vouchers.vouchers && vouchers.vouchers.length > 0) {
    availableVouchers = vouchers.vouchers.filter((item, key) => {
      return item.redeemed === null
    })
    if (availableVouchers.length <= 0) {
      emptyVouchers = true;
    }
  } else {
    emptyVouchers = true
  }

  let location = action?.actionPoints[0];
  let actionStatus = action?.status.user.status === "started" ? true : false

  const pollQuestions = [
    'Posiadam identyfikator',
    'Posiadam tablet',
    'Jestem ubrana/-y w wymagany strój',
    'Posiadam zapas produktu'
  ]

  const pollEndQuestions = [
    'Brak produktu w POP',
    'Brak produkty w moim zapasie',
    "Brak traffic'u w POP",
    'Brak sprzedaży',
    'Zmiana miejsca akcji',
    'Prywatne',
    'Inne'
  ]

  return (
    <section className="action">
      <NavLink to="/akcje" className="back"><Arrow /></NavLink>
      <h1 className="title">{action?.name}</h1>
      <p style={{ textAlign: "center" }}>ID akcji: {action?.ident}</p>
      {action?.status.user.status === "started" ?
        <div className="info center">
          Można już zakończyć akcję.
        </div> : null
      }
      {action?.status.global.ident !== "hst_cancelled" && <>
        <Button className="btn" style={actions?.progress ? { opacity: "0.4" } : {}} action={() => { if (!actions?.progress) toggleAction(actionStatus, action?.uuid) }}>{action?.status.user.status === "started" ? 'Zakończ' : 'Rozpocznij'}</Button>
        {action?.status.user.status !== "started" && <Button className="btn" type="cancel" action={() => setCancelPopup(true)}>Odwołaj</Button>}
      </>}
      <div className="action-info">
        <h3>Informacje</h3>
        <p>Czas trwania akcji: <strong>{action?.since != null && action?.until != null ? <span className="date">{moment(action?.since.date, 'YYYY-MM-DD').format('D-M-Y') + ", " + moment(action?.since.date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') + ' - ' + moment(action?.until.date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')}</span> : ''}</strong></p>
        <h3>Lokalizacja</h3>
        {action?.actionPoints.length > 0 ?
          <>
            <h4>{location.name}</h4>
            <p>{location.address.streetAddress}{location.address.postalCode != null ? ', ' + location.address.postalCode : ''}{', ' + location.address.cityName}</p>
          </>
          : ''}
      </div>
      <Button
        type={action?.status.user.status === "started" ? "orange" : "inactive"}
        action={
          () => {
            if (action?.status.user.status === "started") {
              setBatchesPopup(true)
            }
          }
        }>
        Kody promocyjne</Button>
      <BottomModal open={popup} hidePopup={() => setPopup(false)}>
        {modal === 'codes' ?
          <div className="popup-inner popup-codes">
            {emptyVouchers ?
              <div className="no-vouchers">
                <h3>Brak dostępnych kodów</h3>
                <button className="btn" onClick={() => dispatch(requestVouchers())}>Generuj kody</button>
              </div> : null}
            {availableVouchers.map((item, key) => {
              const cl = item.redeemed ? 'active' : '';
              return (
                <div key={key} className={"code-item " + cl}>
                  <span className="nr">{(key + 1) + '.'}</span>
                  <div className="cupon-code">{item.ident}</div>
                  <Button type="redeem" action={() => dispatch(openRedeemPopup(action, item))}>Wydaj</Button>
                </div>
              )
            })
            }
          </div> :
          <div className="popup-inner">
            { action?.actionPoints.map((item, key) => {
              return (
                <div key={'location-' + key} onClick={() => { 
                 setLocationKey(key);
                  setPopup(false); 
                }}><LocationView data={item} /></div>
              )
            }) }
          </div>
        }
      </BottomModal>
      <PopupModal open={codesPopup} hidePopup={() => setCodesPopup(false) }>
        <div className="popup-inner">
          <h3>{'Wydaję kod ' + selectedItem.ident + ':'}</h3>
          <div className="buttons">
            <Button type="empty" action={() => setCodesPopup(false) }>Anuluj</Button>
            <Button action={() => { setCodesPopup(false); dispatch(redeemVoucher(selectedItem.uuid)); }}>Potwierdź</Button>
          </div>
        </div>
      </PopupModal>
      <PopupModal open={batchesPopup} hidePopup={() => setBatchesPopup(false)}>
        <div className="popup-inner">
          <h3>{'Wybierz kategorię:'}</h3>
          <div className="batches">
            {vouchers.batches ? vouchers.batches.map((item, key) => {
              return (
                <button key={'category-' + key} className="btn" onClick={
                  () => {
                    dispatch(selectBatch(item.uuid));
                    setPopup(true);
                    setModal('codes');
                    setBatchesPopup(false);
                  }
                }>{item.ident}</button>
              )
            }) : null
            }
          </div>
        </div>
      </PopupModal>
      <PopupModal open={confirmPopup} hidePopup={() => setConfirmPopup(false)}>
        <div className="popup-inner">
          <h3>{actionStatus ? "Zakończyć akcję?" : "Rozpocząć akcję?"}</h3>
          { actionStatus ?
              <div className="poll-end">
              { pollEndQuestions.map((item, key)=>{
                return (
                  <div className="checkbox-wrapper poll-checkbox">
                    <label htmlFor={`endpoll${key}`}>
                      <input id={`endpoll${key}`} type="radio" name="endpoll" className='checkbox' onChange={()=>{
                        setEndPollAnswer({id: key, name: item});
                      }} />
                      <span>{ item }</span>
                    </label>
                  </div>
                )
              })}
              {endPollAnswer.id === 5 && <textarea onChange={(e)=>{setUserText(e.target.value)}}></textarea>}
            </div>
            :
            <div className="poll-start">
              { pollQuestions.map((item, key)=>{
                return (
                  <div className="checkbox-wrapper poll-checkbox">
                    <label htmlFor={`startpoll${key}`}>
                      <input id={`startpoll${key}`} type="checkbox" className='checkbox' onChange={()=>{
                        let updatedAnswer = [...startPollAnswer]
                        updatedAnswer[key] = !updatedAnswer[key]
                        setStartPollAnswer(updatedAnswer)
                      }} />
                      <span>{ item }</span>
                    </label>
                  </div>
                )
              })}
            </div>
          }
          <div className="buttons">
            <Button type="empty" action={() => setConfirmPopup(false) }>Anuluj</Button>
            <Button style={actions.progress ? { opacity: "0.4" } : {}} action={
              () => { 
                setConfirmPopup(false); 
                if (actionStatus) {
                  dispatch(sendPollEnd({answer: endPollAnswer.id < 5 ? endPollAnswer.name : userText, id: actionId}))
                } else {
                  dispatch(sendPollStart({answer: startPollAnswer, id: actionId}))
                }
                if (!actions.progress) toggleActionProcess(actionStatus, actionPointID)
              }}>Potwierdź</Button>
          </div>
        </div>
      </PopupModal>
      <PopupModal open={cancelPopup} hidePopup={() => setCancelPopup(false)}>
        <div className="popup-inner">
          <h3>Czy na pewno chesz odwołać swój udział w akcji?</h3>
          <div className="buttons">
            <Button type="empty" action={() => setCancelPopup(false)}>Anuluj</Button>
            <Button action={() => { setCancelPopup(false); dropAction() }}>Potwierdź</Button>
          </div>
        </div>
      </PopupModal>
    </section>
  );
}

export default SingleActionsPage;