import { useLocation } from "react-router";
import BottomNav from "../components/BottomNav";

function Page({children}) {
  const location = useLocation()

  let pageClass = location.pathname.substr(1)+"-page";

  if (pageClass.indexOf('/') > -1) {
    pageClass = pageClass.substr(0, pageClass.indexOf('/') - 1)+"-page"
  }

  if (pageClass === '-page') {
    pageClass = 'welcome-page';
  }

  const hideFooter = children.props.hideFooter;

  return (
    <div className={"page "+pageClass} >
      {children}
      {/* { hideFooter ? null : <BottomNav/> } */}
    </div>
  );
}


export default Page;