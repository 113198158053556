import React from 'react'
import { ReactComponent as Check } from "../../../img/checkbox-check.svg"
import { useWebformStore } from './store'
import { currentStepCopy } from './data'


export default function WebformStep() {
  const webformState = useWebformStore(state => state.webformState)

  console.log(webformState)

  return (
    <>
      <h2 className='webform-title'>
        Webform
      </h2>
      <div className="webform-step">
        <p className='current-step-title'>{currentStepCopy[webformState.step].title}</p>
        <div className='step-progress'>
          {Array(currentStepCopy.length).fill(null).map((_, index) => <div key={index} className={`step ${index <= webformState.step ? "active" : ""}`}>
            {index < webformState.step ?
              <Check />
              :
              <span>{index + 1}</span>}
          </div>)}
        </div>
        <p className='current-step-desc' dangerouslySetInnerHTML={{ __html: currentStepCopy[webformState.step].desc }} />
      </div>
    </>
  )
}
