import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { login, ssoLogin, redirectLogin } from '../actions/user';
import { NavLink, useLocation } from 'react-router-dom'
import { ReactComponent as Logo } from '../../img/logo-bat-blue.svg'
import { useNavigate } from "react-router-dom";
import Button from './Button'

function LoginPage() {
  const dispatch = useDispatch()
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const initGeolocation = () => {
      const options = {
        enableHighAccuracy: true,
        timeout: 2000,
        maximumAge: 0
      }

      function success(pos) {
        localStorage.setItem('geolocationPermission', 'true');
        const crd = pos.coords
        localStorage.setItem("coordinates", JSON.stringify({latitude: crd.latitude, longitude: crd.longitude}))
        console.log('Your current position is:')
        console.log(`Latitude : ${crd.latitude}`)
        console.log(`Longitude: ${crd.longitude}`)
        console.log(`More or less ${crd.accuracy} meters.`)
      }

      function error(err) {
        localStorage.setItem('geolocationPermission', 'false');
        console.warn(`Geolocation: (${err.code}): ${err.message}`)
      }

      navigator.geolocation.getCurrentPosition(success, error, options)
    }

    initGeolocation()
    dispatch(redirectLogin(location.search.substr(7, location.search.length), navigate))
  }, [])

  let validate = (e) => {
    e.preventDefault()

    if (email === "") {
      setError("Wpisz adres email")
    } else if (pass === "") {
      setError("Wpisz hasło")
    } else {
      setError("")
      handleLogin()
    }
  }

  const handleLogin = () => {
    let data = {
      username: email,
      password: pass
    }

    dispatch(login(data, navigate))
  }

  return (
    <section className="login-page">
      <Logo className="bat-logo" />
      <div className="page-inner">
        <h2>Zaloguj się</h2>
        <p>Podaj nazwę użytkownika i hasło</p>
        <form>
          <div className="center">
            <div className="input-wrapper-old">
              <input type="email" placeholder="nazwa użytkownika" value={email} onChange={(e) => { setEmail(e.target.value) }} />
            </div>
          </div>
          <div className="center">
            <div className="input-wrapper-old">
              <input type="password" placeholder="hasło" value={pass} onChange={(e) => { setPass(e.target.value) }} />
            </div>
          </div>
          <div className="remind">
            {'Nie pamiętasz hasła? >'} <NavLink to="/remind">Zresetuj hasło</NavLink>
          </div>
          {error !== "" ? <p className="error">{error}</p> : null}
          <div className="center">
            <Button className="btn" action={(e) => { validate(e) }}>Dalej</Button>
          </div>
          {/*<div className="center">
            <Button className="btn" action={ (e) => dispatch(ssoLogin()) }>SSO Login</Button>
          </div>*/}
        </form>
      </div>
    </section>
  );
}

export default LoginPage;