import React from 'react'
import Modal from '../Modal';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

export default NiceModal.create(({ error }) => {
  const modal = useModal();

  return (
    <Modal open={modal.visible} hidePopup={modal.hide} onAfterClose={modal.remove}>
      <div className="error-popup">
        <h2>Wystąpił błąd.</h2>
        <h3>{error.description}</h3>

        {error.code ?
          <h4>
            Kod błędu: <strong>{error.code}</strong>
          </h4>
          :
          <h4>Spróbuj ponownie...</h4>
        }

        <button onClick={() => {modal.hide(); modal.remove()}} className={`btn btn-dark`}>
          Wróć
        </button>
      </div>
    </Modal>
  )
});
