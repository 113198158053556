import React, { Component } from 'react';
import Calendar from "../components/Calendar";

function CalendarPage() {

  return (
    <section className="calendar-page">
      <Calendar />
    </section>
  );
}

export default CalendarPage;