import {
    MGM_JOIN,
    MGM_VALIDATE,
    MGM_UPDATE,
    MGM_INVITE,
    MGM_SHOW_FORM,
    MGM_NOT_FOUND,
    MGM_CLEAR,
    MGM_PROCESSING,
    MGM_ERROR,
    MGM_COMMERCIAL_OPTIN,
    MGM_MOBILE_OPTIN,
    MGM_SOCIAL_OPTIN,
    MGM_HYPER_OPTIN,
    MGM_SET_EMAIL,
    MGM_GET_RANKING
  } from "../actions/types";
  
  export default function(state = {}, action) {
    switch (action.type) {
      case MGM_VALIDATE:
        let status = 'can-invite';
        /*if (/Velo|Epok/.test(action.payload.brand) || action.payload.vapeStatus === 'Consumer' || action.payload.thpStatus === 'Consumer') {
          if (action.payload.emailOptIn === false || action.payload.emailOptIn === "false" || action.payload.mobileOptIn === false || action.payload.mobileOptIn === "false" || action.payload.hypercareOptIn === false || action.payload.hypercareOptIn === "false" || action.payload.socialMediaOptIn === false || action.payload.socialMediaOptIn === "false") {
            status = 'opt-in-request';
          } else {
            status = 'can-invite';
          }
        } else {
          status = 'not-found';
        }*/
        return {
          ...state,
          token: action.payload.uuid,
          status: status, // 'not-found', 'can-invite', 'opt-in-request', 'opt-in-form', 'finished', null(clear), 'processing', 'already-member, 'no-products', 'no-consents'
        }

      case MGM_CLEAR: 
        return {
          ...state,
          mgm_user: {},
          status: null
        }
      
      case MGM_INVITE: 
        return {
          ...state,
          status: 'finished'
        }
      
      case MGM_UPDATE: 
        return {
          ...state,
          status: 'can-invite'
        }

      case MGM_SHOW_FORM: 
        return {
          ...state,
          status: 'opt-in-form'
        }

      case MGM_NOT_FOUND: 
        return {
          ...state,
          status: 'not-found'
        }
      case MGM_PROCESSING: 
        return {
          ...state,
          status: 'processing'
        }
      case MGM_SET_EMAIL: 
        return {
          ...state,
          mgm_user: { ...state.mgm_user, emailAddress: action.payload.email}
        }
      case MGM_ERROR: 
        let errorStatus = 'error'
        let consents = state.consents;
        let token = '';
        if ( action.payload.error === 'E_NO_CONSENTS') {
          errorStatus = 'no-consents';
          consents = action.payload.details
          token = action.payload.details.token.uuid
        } else if ( action.payload.error === 'E_NO_PRODUCTS' ) {
          errorStatus = 'no-products';
        } else if ( action.payload.error === 'E_ALREADY_MEMBER' ) {
          errorStatus = 'already-member';
        } 
        return {
          ...state,
          status: errorStatus,
          errorDetails: action.payload.details,
          consents: consents,
          token: token
        }
      case MGM_COMMERCIAL_OPTIN: 
        return {
          ...state,
          consents: {...state.consents, newsletterCommercialAuthorization: action.payload}
        }
      case MGM_MOBILE_OPTIN: 
        return {
          ...state,
          consents: {...state.consents, mobileOptIn: action.payload}
        }
      case MGM_SOCIAL_OPTIN: 
        return {
          ...state,
          consents: {...state.consents, socialMediaOptIn: action.payload}
        }
      case MGM_HYPER_OPTIN: 
        return {
          ...state,
          consents: {...state.consents, hypercareOptIn: action.payload}
        }
      case MGM_GET_RANKING: 
        return {
          ...state,
          ranking: action.payload
        }
      default:
        return state;
    }
  }

  export const selectMGM = (state) => state.mgm