import _ from 'lodash'
import {
  START_ACTION,
  STOP_ACTION,
  PROGRESS_ACTION,
  ERROR_ACTION,
  UPLOAD_ACTIONS
} from "../actions/types";

function redeem(parentArray, array, action) {
  return parentArray.map((item, index) => {
    if (item.id !== action.actionId) {
      return item
    }
    
    return {
      ...item,
      codes: array.map((code, i) => {
        if (code.id !== action.codeId) {
          return code
        }

        return {
          ...code,
          redeemed: true
        }
      })
    }
  })
}

function start(array, id) {
  return array.map((item, index) => {
    if (item.id !== id) {
      return item
    }

    return {
      ...item,
      status: true
    }
  })
}

function stop(array, id) {
  return array.map((item, index) => {
    if (item.id !== id) {
      return item
    }

    return {
      ...item,
      status: false
    }
  })
}

export default function(state = {}, action) {
  switch (action.type) {
    case START_ACTION:
      return {
        ...state,
        list: start(state.list, action.actionId),
        currentAction: action,
        progress: false
      }
    case STOP_ACTION:
        return {
          ...state,
          list: stop(state.list, action.actionId),

          progress: false
        }
    case PROGRESS_ACTION:
        return {
          ...state,
          progress: true
        }
    case ERROR_ACTION:
        return {
          ...state,
          progress: false
        }
    case UPLOAD_ACTIONS:
      const currentAction = state?.list?.find(action => action.status.user.status === 'started')
      return {
        ...state,
        list: action.payload,
        currentAction: currentAction,
        progress: false
      }
    default:
      return state;
  }
}

export const selectActions = (state) => state.actions;
export const getActionID = (state) => state.actions.currentAction;
export const getCurrentAction = (state) => state.actions?.list?.find(action => action.status.user.status === 'started')