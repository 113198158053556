import { 
  UPLOAD_REPORTS,
  UPLOAD_AVAILABILITY,
  CREATE_AVAILABILITY,
  UPDATE_AVAILABILITY,
  DELETE_AVAILABILITY,
  ADD_TOAST,
  GET_RECENT,
  GET_STATS
} from './types';
import { addToast } from './toast';
import { API_URL, appFetch } from '../helpers/fetch'
import { errors } from '../helpers/errors'
import moment from 'moment'

export function uploadReports(since, until, page) {
  return (dispatch, getState) => { 
    const currentState = getState()

    const data = {
      "actions": {
        "since": moment('2020-01-01').format('YYYY-MM-DD'),
        "until": moment().format('YYYY-MM-DD'),
        "finished": true
      },
      "pagination": {
        "limit": 100,
        "offset": page*100||0
      }
    }
    appFetch("POST", API_URL, "/action/list", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
        
          dispatch({ type: UPLOAD_REPORTS, payload: result.data });
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function getRecent() {
  return (dispatch, getState) => { 
    const currentState = getState()

    appFetch("POST", API_URL, "/action/recent", null, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: GET_RECENT, payload: result.data });
          dispatch(getStats(currentState.user.user.ident, result.data.action?.current, result.data.action?.last))
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function getStats(hostessCode, currentAction, lastAction) {
  return (dispatch, getState) => { 
    const currentState = getState()

    const data = {
      sample: {
        hostessCode: hostessCode,
        currentAction: currentAction,
        lastAction: lastAction
      }
    }

    appFetch("POST", process.env.REACT_APP_API_WEBFORM_URL, "/sample/stats", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
        
          dispatch({ type: GET_STATS, payload: result.data });
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function uploadAvailability() {
  const today = moment()
    const data = {
      "availability": {
          "since": today.format('YYYY-MM-DD'),
          "until": today.add(2, 'months').format('YYYY-MM') + "-" + today.add(2, 'months').daysInMonth()
      }
    }
  return (dispatch, getState) => { 
    const currentState = getState()
    appFetch("POST", API_URL, "/availability/list", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: UPLOAD_AVAILABILITY, payload: result.data.availability });
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function createAvailability(data) {
  return (dispatch, getState) => { 
    const currentState = getState()
    appFetch("POST", API_URL, "/availability/create", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: CREATE_AVAILABILITY, payload: null });
          dispatch(uploadAvailability())
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function updateAvailability(data) {
  return (dispatch, getState) => { 
    const currentState = getState()
    appFetch("POST", API_URL, "/availability/update", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: CREATE_AVAILABILITY, payload: null });
          dispatch(uploadAvailability())
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function deleteAvailability(data) {
  return (dispatch, getState) => { 
    const currentState = getState()
    appFetch("POST", API_URL, "/availability/delete", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: DELETE_AVAILABILITY, payload: null });
          dispatch(uploadAvailability())
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}