import NiceModal from '@ebay/nice-modal-react';
import AvailableModal from './AvailableModal';
import ErrorModal from './ErrorModal';
import OTPErrorModal from './OTPErrorModal';
import PhotosSentModal from '../PhotosSentModal';
import PhotosCaptureModal from '../PhotosCaptureModal';

NiceModal.register('webform-error-modal', ErrorModal);
NiceModal.register('webform-available-modal', AvailableModal);
NiceModal.register('webform-otp-error-modal', OTPErrorModal);
NiceModal.register('photos-sent-modal', PhotosSentModal);
NiceModal.register('photos-capture-modal', PhotosCaptureModal);
