/* Component for easier ES6 binding */

import { Component } from "react";

export default class BaseComponent extends Component {
  // The rest parameter syntax allows to represent an indefinite number of arguments as an array.
  // https://developer.mozilla.org/pl/docs/Web/JavaScript/Reference/Functions/rest_parameters
  _bind(...methods) {
    methods.forEach((method) => (this[method] = this[method].bind(this)));
  }

  setContent(id) {
    this.setState({ index: id });
  }

  leadingZero(day) {
    let d = parseInt(day);
    if (d < 10) {
      return "0" + d;
    } else {
      return "" + d;
    }
  }

  parseDate(d, hours, showSeconds) {
    let date = new Date(d);
    let seconds = ":" + this.leadingZero(date.getSeconds());

    if (showSeconds === false) {
      seconds = "";
    }

    if (hours) {
      return (
        this.leadingZero(date.getDate()) +
        "/" +
        this.leadingZero(date.getMonth() + 1) +
        "/" +
        date.getFullYear() +
        ", " +
        this.leadingZero(date.getHours()) +
        ":" +
        this.leadingZero(date.getMinutes()) +
        seconds
      );
    } else {
      return (
        this.leadingZero(date.getDate()) +
        "/" +
        this.leadingZero(date.getMonth() + 1) +
        "/" +
        date.getFullYear()
      );
    }
  }
}

export function appFetch(method, endpoint, data, success, failed) {
  if (window.online) {
    fetch(window.apiurl + endpoint, {
      method: method,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + sessionStorage.getItem('token')
      },
      body: method === "GET" ? null : JSON.stringify(data)
    })
      .then((response) => {
        return response.json()
      })
      .then((result) => {
        console.log("fetch:", result)
        if (result.code && result.code === 401 && endpoint !== "/api/login_check") {
          window.location.hash = "/login"
        } else {
          if (endpoint !== "/api/login_check" &&
            endpoint !== "/api/auth/check/credentials" &&
            endpoint !== "/api/auth/check/otp" &&
            endpoint !== "/api/user/password/reminder" &&
            endpoint !== "/api/user/password/change" &&
            endpoint !== "/api/cart/submit") {
            localStorage.setItem(endpoint, JSON.stringify(result))
          }
          success(result)
        }
      }, (error) => {
        failed(error)
      })
  } else if (!window.online && window.requestQueue && endpoint === "/api/cart/submit") {
    localStorage.setItem('offlineCart', localStorage.getItem('cart'))
    localStorage.removeItem('cart')
    window.requestQueue.enqueue({
      method: method,
      url: window.apiurl + endpoint,
      headers: [
        {
          "header": "Content-Type",
          "value": "application/json; charset=utf-8"
        },
        {
          "header": "Authorization",
          "value": "Bearer " + sessionStorage.getItem('token')
        }
      ],
      "contentType": "json",
      body: method === "GET" ? null : data,
      callback: (result) => {
        if (result.body.status) {
          localStorage.removeItem('offlineCart')
          window.location.hash = "/summary/success"
        } else {
          window.location.hash = "/summary/failed"
        }
        console.log("queue:", result)
        if (result.code && result.code === 401 && endpoint !== "/api/login_check") {
          window.location.hash = "/login"
        } else {
          success(result)
          if (!window.online &&
            endpoint !== "/api/login_check" &&
            endpoint !== "/api/auth/check/credentials" &&
            endpoint !== "/api/auth/check/otp" &&
            endpoint !== "/api/user/password/reminder" &&
            endpoint !== "/api/user/password/change" &&
            endpoint !== "/api/cart/submit") {
            localStorage.setItem(endpoint, JSON.stringify(result))
          }
        }
      }
    })
  } else if (!window.online &&
    endpoint !== "/api/login_check" &&
    endpoint !== "/api/auth/check/credentials" &&
    endpoint !== "/api/auth/check/otp" &&
    endpoint !== "/api/user/password/reminder" &&
    endpoint !== "/api/user/password/change") {
    if (localStorage.getItem(endpoint)) {
      console.log("local:", JSON.parse(localStorage.getItem(endpoint)))
      success(JSON.parse(localStorage.getItem(endpoint)))
    } else {
      failed("offline")
    }
  }
}

export function getLocation() {
  if (!JSON.parse(localStorage.getItem('geolocationPermission'))) {
    return false
  }
  
  const options = {
    enableHighAccuracy: true,
    timeout: 2000,
    maximumAge: 0
  }

  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  )
}

export function leadingZero(day) {
  let d = parseInt(day);
  if (d < 10) {
    return "0" + d;
  } else {
    return "" + d;
  }
}