import React from 'react'
import Modal from '../Modal';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

export default NiceModal.create(({ error }) => {
  const modal = useModal();

  return (
    <Modal open={modal.visible} hidePopup={modal.hide} onAfterClose={modal.remove}>
      <div className="error-popup">
        {["error_wrong_otp_email", "error_wrong_otp"].includes(error) ?
          <h3>Błędny kod OTP {error === "error_wrong_otp" ? "telefonu" : "e-mail"}</h3>
          :
          <h3>
            Błąd weryfikacji
          </h3>
        }
        <button onClick={() => { modal.hide(); modal.remove() }} className={`btn btn-dark`}>
          OK
        </button>
      </div>
    </Modal>
  )
});
