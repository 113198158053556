import React, { Component } from 'react';
import { connect } from 'react-redux';
import SingleToast from './SingleToast';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { removeToast } from '../../actions/toast'

class Toasts extends Component {

  constructor(props) {
    super(props)
    this.state = {  }
  }

  componentWillMount() {
   
  }

  componentDidMount() {
   
  }

  render() {

    return (
      <div className="toasts">
        <TransitionGroup>
        { this.props.toasts.map((toast, key) => {
          return (
            <CSSTransition
              key={key}
              classNames="toast"
              timeout={{ enter: 500, exit: 300 }}
            >
              <SingleToast { ...toast } remove={ () => { this.props.removeToast(toast.id) } } />
            </CSSTransition>
          )
        })}
        </TransitionGroup>
      </div>
    )
  }
}

const mapStateToProps = ({ 
  toasts
}) => ({
  toasts
});

export default connect(
  mapStateToProps,
  {
    removeToast
  }
)(Toasts);