//import throttle from 'lodash/throttle';
//import { createBrowserHistory } from 'history';
import { createStore, compose, applyMiddleware } from "redux";
//import { routerMiddleware } from 'connected-react-router';
//import apiMiddleware from "../middleware/api";
import thunk from 'redux-thunk'
import createRootReducer from '../reducers';
import { saveState } from '../localStorage';

//export const history = createBrowserHistory()

const loggerMiddleware = store => next => action => {
  if (process.env.REACT_APP_BRANCH === 'dev') {
    console.log("Action type: "+ (action ? action.type : ""));
    console.log("Action payload:", (action ? action.payload : ""));
    console.log("State before:", store.getState());
  }
  if(action) next(action);
  if (process.env.REACT_APP_BRANCH === 'dev') {
    console.log("State after:", store.getState());
  }
};

export default function configureStore(preloadedState) {

	const store = createStore(
    createRootReducer(), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        //routerMiddleware(history), // for dispatching history actions
        loggerMiddleware,
        thunk
        // ... other middlewares ...
      ),
    ),
  )

  store.subscribe(() => {
    //console.log('STATE SAVE')
    saveState(store.getState())
  })

	window.store = store;
  return store
}
