export const ADD_TOAST = "ADD_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";

export const SCREEN_DIMENSIONS_SAVE = "SCREEN_DIMENSIONS_SAVE";

export const START_ACTION = "START_ACTION";
export const STOP_ACTION = "STOP_ACTION";
export const PROGRESS_ACTION = "PROGRESS_ACTION";
export const ERROR_ACTION = "ERROR_ACTION";

export const UPLOAD_ACTIONS = "UPLOAD_ACTIONS";
export const UPLOAD_REPORTS = "UPLOAD_REPORTS";
export const UPLOAD_AVAILABILITY = "UPLOAD_AVAILABILITY";
export const CREATE_AVAILABILITY = "CREATE_AVAILABILITY";
export const UPDATE_AVAILABILITY = "UPDATE_AVAILABILITY";
export const DELETE_AVAILABILITY = "DELETE_AVAILABILITY";
export const GET_RECENT = "GET_RECENT";
export const GET_STATS = "GET_STATS";

export const GET_BATCHES_SUCCESS = "GET_BATCHES";
export const GET_VOUCHERS_REQUEST = "GET_VOUCHERS_REQUEST";
export const GET_VOUCHERS_SUCCESS = "GET_VOUCHERS_SUCCESS";
export const REQUEST_VOUCHERS = "REQUEST_VOUCHERS";
export const REDEEM_VOUCHER = "REDEEM_VOUCHER";
export const SELECT_BATCH = "SELECT_BATCH";

export const TIMER_START = "TIMER_START";
export const TIMER_FINISHED = "TIMER_FINISHED";

// routing
export const NAVIGATE_HOME = "NAVIGATE_HOME";

// user
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REMIND = "REMIND";
export const REMIND_OTP = "REMIND_OTP";
export const SESSION = "SESSION";
export const CHANGE_PASS = "CHANGE_PASS";

export const MGM_JOIN = "MGM_JOIN";
export const MGM_VALIDATE = "MGM_VALIDATE";
export const MGM_UPDATE = "MGM_UPDATE";
export const MGM_INVITE = "MGM_INVITE";
export const MGM_CLEAR = "MGM_CLEAR";
export const MGM_SHOW_FORM = "MGM_SHOW_FORM";
export const MGM_NOT_FOUND = "MGM_NOT_FOUND";
export const MGM_PROCESSING = "MGM_PROCESSING";
export const MGM_ERROR = "MGM_ERROR";
export const MGM_COMMERCIAL_OPTIN = "MGM_COMMERCIAL_OPTIN";
export const MGM_MOBILE_OPTIN = "MGM_MOBILE_OPTIN";
export const MGM_SOCIAL_OPTIN = "MGM_SOCIAL_OPTIN";
export const MGM_HYPER_OPTIN = "MGM_HYPER_OPTIN";
export const MGM_SET_EMAIL = "MGM_SET_EMAIL";
export const MGM_GET_RANKING = "MGM_GET_RANKING";