import React, { useState, useEffect } from 'react';
import { uploadReports, getRecent } from '../actions/reports'
import { NavLink } from 'react-router-dom';
import _ from 'lodash'
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { selectReports } from '../reducers/reports';

function ReportsPage() {
  const dispatch = useDispatch();
  const reports = useSelector(selectReports)
  const [open, setOpen] = useState({
    currentAction: {
      glo: false,
      vuse: false,
      velo: false
    },
    lastAction: {
      glo: false,
      vuse: false,
      velo: false
    },
    currentMonth: {
      glo: false,
      vuse: false,
      velo: false
    },
    lastMonth: {
      glo: false,
      vuse: false,
      velo: false
    }
  })
  useEffect(()=>{
    dispatch(uploadReports())
    dispatch(getRecent())
  },[])

  const getCount = (stat, brand) => {
    let count = 0
    for (let i = 0; i < _.filter(stat, { brand: brand }).length; i++) {
      const element = _.filter(stat, { brand: brand })[i];
      count += element.count
    }
    return count
  }

  const toggleProducts = (stat, product) => {
    let _open = {...open};
    if(_open[stat][product]) {
      _open[stat][product] = false
    } else {
      _open[stat][product] = true
    }
    setOpen(_open)
  }

  const currentAction = reports?.stats?.sample?.currentAction || []
  const currentActionCountGlo = getCount(currentAction, "Glo")
  const currentActionCountVuse = getCount(currentAction, "Vuse")
  const currentActionCountVelo = getCount(currentAction, "Velo")

  const lastAction = reports?.stats?.sample?.lastAction || []
  const lastActionCountGlo = getCount(lastAction, "Glo")
  const lastActionCountVuse = getCount(lastAction, "Vuse")
  const lastActionCountVelo = getCount(lastAction, "Velo")

  const currentMonth = reports?.stats?.sample?.currentMonth || []
  const currentMonthCountGlo = getCount(currentMonth, "Glo")
  const currentMonthCountVuse = getCount(currentMonth, "Vuse")
  const currentMonthCountVelo = getCount(currentMonth, "Velo")

  const lastMonth = reports?.stats?.sample?.lastMonth || []
  const lastMonthCountGlo = getCount(lastMonth, "Glo")
  const lastMonthCountVuse = getCount(lastMonth, "Vuse")
  const lastMonthCountVelo = getCount(lastMonth, "Velo")

  return (
    <section className="reports">
      <h2 className="title">Raporty</h2>
      <>
        <h3>Ilość sprzedanych urządzeń:</h3>
        <div className="reports-stats">
        { currentAction.length > 0 && <div>
            <h4>Aktualna akcja:</h4>
            <ul className="top">
            { currentActionCountGlo > 0 &&<li className="stat-item">
                <span className="name">glo</span>
                <span className={ open["currentAction"]["glo"] ? "toggle open" : "toggle" } onClick={ () => { toggleProducts("currentAction", "glo") } }></span>
                <span className="count">{ currentActionCountGlo }</span>
                { open["currentAction"]["glo"] && <ul className="sub">
                  { _.filter(currentAction, { brand: "Glo" }).map((item, i) => {
                    return (
                      <li key={i}>
                        <span className="name">{ item.model }</span>
                        <span className="count">{ item.count }</span>
                      </li>
                    )
                  }) }
                </ul> }
              </li> }
              { currentActionCountVuse > 0 &&<li className="stat-item">
                <span className="name">vuse</span>
                <span className={ open["currentAction"]["vuse"] ? "toggle open" : "toggle" } onClick={ () => { toggleProducts("currentAction", "vuse") } }></span>
                <span className="count">{ currentActionCountVuse }</span>
                { open["currentAction"]["vuse"] && <ul className="sub">
                  { _.filter(currentAction, { brand: "Vuse" }).map((item, i) => {
                    return (
                      <li key={i}>
                        <span className="name">{ item.model }</span>
                        <span className="count">{ item.count }</span>
                      </li>
                    )
                  }) }
                </ul> }
              </li> }
              { currentActionCountVelo > 0 &&<li className="stat-item">
                <span className="name">velo</span>
                <span className={ open["currentAction"]["velo"] ? "toggle open" : "toggle" } onClick={ () => { toggleProducts("currentAction", "velo") } }></span>
                <span className="count">{ currentActionCountVelo }</span>
                { open["currentAction"]["velo"] && <ul className="sub">
                  { _.filter(currentAction, { brand: "Velo" }).map((item, i) => {
                    return (
                      <li key={i}>
                        <span className="name">{ item.model }</span>
                        <span className="count">{ item.count }</span>
                      </li>
                    )
                  }) }
                </ul> }
              </li> }
            </ul>
          </div> }
          { lastAction.length > 0 && <div>
            <h4>Ostatnia zakończona akcja:</h4>
            <ul className="top">
              { lastActionCountGlo > 0 && <li className="stat-item">
                <span className="name">glo</span>
                <span className={ open["lastAction"]["glo"] ? "toggle open" : "toggle" } onClick={ () => { toggleProducts("lastAction", "glo") } }></span>
                <span className="count">{ lastActionCountGlo }</span>
                { open["lastAction"]["glo"] && <ul className="sub">
                  { _.filter(lastAction, { brand: "Glo" }).map((item, i) => {
                    return (
                      <li key={i}>
                        <span className="name">{ item.model === "nano" ? "Hyper Air" : item.model }</span>
                        <span className="count">{ item.count }</span>
                      </li>
                    )
                  }) }
                </ul> }
              </li> }
              { lastActionCountVuse > 0 && <li className="stat-item">
                <span className="name">vuse</span>
                <span className={ open["lastAction"]["vuse"] ? "toggle open" : "toggle" } onClick={ () => { toggleProducts("lastAction", "vuse") } }></span>
                <span className="count">{ lastActionCountVuse }</span>
                { open["lastAction"]["vuse"] && <ul className="sub">
                  { _.filter(lastAction, { brand: "Vuse" }).map((item, i) => {
                    return (
                      <li key={i}>
                        <span className="name">{ item.model }</span>
                        <span className="count">{ item.count }</span>
                      </li>
                    )
                  }) }
                </ul> }
              </li> }
              { lastActionCountVelo > 0 &&<li className="stat-item">
                <span className="name">velo</span>
                <span className={ open["lastAction"]["velo"] ? "toggle open" : "toggle" } onClick={ () => { toggleProducts("lastAction", "velo") } }></span>
                <span className="count">{ lastActionCountVelo }</span>
                { open["lastAction"]["velo"] && <ul className="sub">
                  { _.filter(lastAction, { brand: "Velo" }).map((item, i) => {
                    return (
                      <li key={i}>
                        <span className="name">{ item.model }</span>
                        <span className="count">{ item.count }</span>
                      </li>
                    )
                  }) }
                </ul> }
              </li> }
            </ul>
          </div> }
          { currentMonth.length > 0 && <div>
            <h4>Bieżący miesiąc:</h4>
            <ul className="top">
              { currentMonthCountGlo > 0 && <li className="stat-item">
                <span className="name">glo</span>
                <span className={ open["currentMonth"]["glo"] ? "toggle open" : "toggle" } onClick={ () => { toggleProducts("currentMonth", "glo") } }></span>
                <span className="count">{ currentMonthCountGlo }</span>
                { open["currentMonth"]["glo"] && <ul className="sub">
                  { _.filter(currentMonth, { brand: "Glo" }).map((item, i) => {
                    return (
                      <li key={i}>
                        <span className="name">{ item.model === "nano" ? "Hyper Air" : item.model }</span>
                        <span className="count">{ item.count }</span>
                      </li>
                    )
                  }) }
                </ul> }
              </li> }
              { currentMonthCountVuse > 0 && <li className="stat-item">
                <span className="name">vuse</span>
                <span className={ open["currentMonth"]["vuse"] ? "toggle open" : "toggle" } onClick={ () => { toggleProducts("currentMonth", "vuse") } }></span>
                <span className="count">{ currentMonthCountVuse }</span>
                { open["currentMonth"]["vuse"] && <ul className="sub">
                  { _.filter(currentMonth, { brand: "Vuse" }).map((item, i) => {
                    return (
                      <li key={i}>
                        <span className="name">{ item.model }</span>
                        <span className="count">{ item.count }</span>
                      </li>
                    )
                  }) }
                </ul> }
              </li> }
              { currentMonthCountVelo > 0 && <li className="stat-item">
                <span className="name">velo</span>
                <span className={ open["currentMonth"]["velo"] ? "toggle open" : "toggle" } onClick={ () => { toggleProducts("currentMonth", "velo") } }></span>
                <span className="count">{ currentMonthCountVelo }</span>
                { open["currentMonth"]["velo"] && <ul className="sub">
                  { _.filter(currentMonth, { brand: "Velo" }).map((item, i) => {
                    return (
                      <li key={i}>
                        <span className="name">{ item.model }</span>
                        <span className="count">{ item.count }</span>
                      </li>
                    )
                  }) }
                </ul> }
              </li> }
            </ul>
          </div> }
          { lastMonth.length > 0 && <div>
            <h4>Ostatni miesiąc:</h4>
            <ul className="top">
              { lastMonthCountGlo > 0 && <li className="stat-item">
                <span className="name">glo</span>
                <span className={ open["lastMonth"]["glo"] ? "toggle open" : "toggle" } onClick={ () => { toggleProducts("lastMonth", "glo") } }></span>
                <span className="count">{ lastMonthCountGlo }</span>
                { open["lastMonth"]["glo"] && <ul className="sub">
                  { _.filter(lastMonth, { brand: "Glo" }).map((item, i) => {
                    return (
                      <li key={i}>
                        <span className="name">{ item.model === "nano" ? "Hyper Air" : item.model }</span>
                        <span className="count">{ item.count }</span>
                      </li>
                    )
                  }) }
                </ul> }
              </li> }
              { lastMonthCountVuse > 0 && <li className="stat-item">
                <span className="name">vuse</span>
                <span className={ open["lastMonth"]["vuse"] ? "toggle open" : "toggle" } onClick={ () => { toggleProducts("lastMonth", "vuse") } }></span>
                <span className="count">{ lastMonthCountVuse }</span>
                { open["lastMonth"]["vuse"] && <ul className="sub">
                  { _.filter(lastMonth, { brand: "Vuse" }).map((item, i) => {
                    return (
                      <li key={i}>
                        <span className="name">{ item.model }</span>
                        <span className="count">{ item.count }</span>
                      </li>
                    )
                  }) }
                </ul> }
              </li> }
              { lastMonthCountVelo > 0 && <li className="stat-item">
                <span className="name">velo</span>
                <span className={ open["lastMonth"]["velo"] ? "toggle open" : "toggle" } onClick={ () => { toggleProducts("lastMonth", "velo") } }></span>
                <span className="count">{ lastMonthCountVelo }</span>
                { open["lastMonth"]["velo"] && <ul className="sub">
                  { _.filter(lastMonth, { brand: "Velo" }).map((item, i) => {
                    return (
                      <li key={i}>
                        <span className="name">{ item.model }</span>
                        <span className="count">{ item.count }</span>
                      </li>
                    )
                  }) }
                </ul> }
              </li> }
            </ul>
          </div> }
        </div>
      </>
      <h3>Raporty z akcji:</h3>
      { reports.list.length > 0 ? reports.list.map((item, key) => {
          return (
            <NavLink to={"/raport/"+item.uuid} key={ 'report-'+key } className={ "reports-item" }>
              <div className="report-name">{ item.name }</div>
              <div className="date">{ moment(item.since.date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY') }</div>
            </NavLink>
          )
        })
      : <h3 style={{ textAlign: "center" }}>Brak zakończonych akcji.</h3>}
    </section>
  );
}

export default ReportsPage;