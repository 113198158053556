import { 
  MGM_JOIN,
  MGM_VALIDATE,
  MGM_UPDATE,
  MGM_INVITE,
  MGM_CLEAR,
  MGM_SHOW_FORM,
  MGM_NOT_FOUND,
  MGM_PROCESSING,
  MGM_ERROR,
  ADD_TOAST,
  MGM_COMMERCIAL_OPTIN,
  MGM_MOBILE_OPTIN,
  MGM_SOCIAL_OPTIN,
  MGM_HYPER_OPTIN,
  MGM_SET_EMAIL,
  MGM_GET_RANKING
} from './types';
import { addToast } from './toast';
import { API_URL, appFetch, appFetchBasic } from '../helpers/fetch'
import { errors } from '../helpers/errors'
import moment from 'moment'

export function mgmJoin() {

  return (dispatch, getState) => { 
    const currentState = getState()
    appFetch("POST", API_URL, "/mgm/maybe-join", {}, currentState.user.token.uuid, (result) => {
      if(result.status && result.status.success) {
        dispatch({ type: MGM_GET_RANKING, payload: result.data });
      } else {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
      }
    }, (error) => {
      console.log(error)
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function mgmValidate(email) {

  const data = {
    consumer: {
      emailAddress: email
    }
  }

  return (dispatch, getState) => { 
    const currentState = getState()

    dispatch({ type: MGM_PROCESSING, payload: {} });
    dispatch({ type: MGM_SET_EMAIL, payload: {email: email} });

    appFetch("POST", API_URL, "/mgm/validate-consumer", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
        dispatch({ type: MGM_VALIDATE, payload: result.data });
      } else {
        dispatch({ type: MGM_ERROR, payload: { error: result.data.error, details: result.data.details } });
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
      }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function mgmInvite(email) {

  return (dispatch, getState) => { 
    const currentState = getState()

    const data = {
      consumer: {
          emailAddress: currentState.mgm.mgm_user.emailAddress
      }
    }

    dispatch({ type: MGM_PROCESSING, payload: {} });

    appFetch("POST", API_URL, "/mgm/invite-consumer", data, currentState.mgm.token, (result) => {
      if(result.status && result.status.success) {
        dispatch({ type: MGM_INVITE, payload: result.data[0] });
      } else {
        dispatch({ type: MGM_ERROR, payload: {} });
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
      }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function mgmUpdate() {

  return (dispatch, getState) => { 
    const currentState = getState()

    const data = {
      consumer: {
        mobileOptIn: currentState.mgm.consents.mobileOptIn,
        emailOptIn: true,
        hypercareOptIn: currentState.mgm.consents.hypercareOptIn,
        socialMediaOptIn: currentState.mgm.consents.socialMediaOptIn,
        emailAddress: currentState.mgm.mgm_user.emailAddress,
        brand: currentState.mgm.mgm_user.brand,
        newsletterCommercialAuthorization: currentState.mgm.consents.newsletterCommercialAuthorization,
        lastModifiedSource: "Hostess"
      }
    }

    dispatch({ type: MGM_PROCESSING, payload: {} });

    appFetch("POST", API_URL, "/mgm/update-consumer", data, currentState.mgm.token, (result) => {
      if(result.status && result.status.success) {
        dispatch({ type: MGM_UPDATE, payload: {} });
        dispatch(mgmValidate(currentState.mgm.mgm_user.emailAddress))
      } else {
        dispatch({ type: MGM_ERROR, payload: { error: result.data.error, details: result.data.details } });
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
      }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function mgmClear() {
  return (dispatch, getState) => { 
    dispatch({ type: MGM_CLEAR, payload: '' });
  }
}

export function mgmShowForm() {
  return (dispatch, getState) => { 
    dispatch({ type: MGM_SHOW_FORM, payload: '' });
  }
}

export function setMobileOptIn(value) {
  return (dispatch, getState) => { 
    dispatch({ type: MGM_MOBILE_OPTIN, payload: value });
  }
}

export function setCommercialOptIn(value) {
  return (dispatch, getState) => { 
    dispatch({ type: MGM_COMMERCIAL_OPTIN, payload: value });
  }
}

export function setSocialOptIn(value) {
  return (dispatch, getState) => { 
    dispatch({ type: MGM_SOCIAL_OPTIN, payload: value });
  }
}

export function setHypercareOptIn(value) {
  return (dispatch, getState) => { 
    dispatch({ type: MGM_HYPER_OPTIN, payload: value });
  }
}