import { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from '../actions/user'
import { ReactComponent as ActionIcon } from '../../img/akcje.svg'
import { ReactComponent as CalendarIcon } from '../../img/calendar-icon.svg'
import { ReactComponent as ReportIcon } from '../../img/raporty.svg'
import { ReactComponent as AccountIcon } from '../../img/twoje-konto.svg'
import { ReactComponent as LogoutIcon } from '../../img/wyloguj.svg'
import { ReactComponent as ImageIcon } from '../../img/image.svg'
import { getUser } from "../reducers/user";
import { selectVouchers } from '../reducers/vouchers';
import { redeemVoucher, requestVouchers, getBatches, getVouchers, selectBatch } from '../actions/vouchers';
import { getCurrentAction } from '../reducers/actions';
import BottomModal from './BottomModal';
import Button from './Button';
import PopupModal from './PopupModal';
import LocationView from './LocationView';
import coupon from '../../img/coupon.png'

function Header() {
  const dispatch = useDispatch();
  const [nav, setNav] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const vouchers = useSelector(selectVouchers);
	const [popup, setPopup] = useState(false);
	const [batchesPopup, setBatchesPopup] = useState(false);
	const [modal, setModal] = useState('codes');
	const action = useSelector(getCurrentAction);
	const [selectedItem, setSelectedItem] = useState(0);
	const [codesPopup, setCodesPopup] = useState(false);

  const toggleNav = () => {
    if (nav) {
      setNav(false)
    } else {
      setNav(true)
    }
  }

  let emptyVouchers = false;
  let availableVouchers = [];
  if (vouchers.vouchers && vouchers.vouchers.length > 0) {
    availableVouchers = vouchers.vouchers.filter((item, key) => {
      return item.redeemed === null
    })
    if (availableVouchers.length <= 0) {
      emptyVouchers = true;
    }
  } else {
    emptyVouchers = true
  }

	const openRedeemPopup = (action, item) => {
    if (!item.redeemed) {
      //setSelectedActionId(action.uuid);
      setSelectedItem(item);
      setCodesPopup(true);
    }
  }

  const requestCodes = () => {
    const data = {
      "action": {
        "uuid": action.uuid
      }
    }

    dispatch(requestCodes(data));
  }

  return (
    <header>
      <div className="burger" onClick={ () => { toggleNav() } }><span></span></div>
      <div className="codes-icon" onClick={(e)=>{
						e.preventDefault()
            if (action?.status.user.status === "started") {
              setBatchesPopup(true)
            }
					}}>
        <img src={coupon} />
      </div>
      <nav className={ nav ? "open" : "" }>
        <div className="close" onClick={ () => { toggleNav() } }></div>
        <div className="nav-top">
          {/* <Avatar/> */}
          <h3><span>Witaj</span><br/>{ user ? user.firstname : "" }</h3>
        </div>
        <div className="separator"></div>
        <ul>
          <li>
            <NavLink to="/akcje" onClick={ () => { setNav(false) } }>Akcje<ActionIcon/></NavLink>
          </li>
          <li>
            <NavLink to="/kalendarz" onClick={ () => { setNav(false) } }>Kalendarz<CalendarIcon/></NavLink>
          </li>
          <li>
            <NavLink to="/raporty" onClick={ () => { setNav(false) } }>Raporty<ReportIcon/></NavLink>
          </li>
          {/*<li>
            <NavLink to="/mgm" onClick={ () => { setNav(false) } }>Strefa Wyboru<ReportIcon/></NavLink>
          </li>*/}
          <li>
            <NavLink to="/webform" onClick={ () => { setNav(false) } }>Webform<ReportIcon/></NavLink>
          </li>
          <li>
            <NavLink to="/photos" onClick={ () => { setNav(false) } }>Zdjęcia produktów<ImageIcon/></NavLink>
          </li>
        </ul>
        <div className="separator" style={{ marginTop: "60px" }}></div>
        <ul>
          <li>
            <NavLink to="/user-data" onClick={ () => { setNav(false) } }>Twoje dane<AccountIcon/></NavLink>
          </li>
          <li>
            <span onClick={ () => { setNav(false); dispatch(logout(navigate)) } }>Wyloguj<LogoutIcon/></span>
          </li>
        </ul>
      </nav>

      <PopupModal open={batchesPopup} hidePopup={() => setBatchesPopup(false)}>
        <div className="popup-inner">
          <h3>{'Wybierz kategorię:'}</h3>
          <div className="batches">
            {vouchers.batches ? vouchers.batches.map((item, key) => {
              return (
                <button key={'category-' + key} className="btn" onClick={
                  () => {
                    dispatch(selectBatch(item.uuid));
                    setPopup(true);
                    setModal('codes');
                    setBatchesPopup(false);
                  }
                }>{item.ident}</button>
              )
            }) : null
            }
          </div>
        </div>
      </PopupModal>

      <BottomModal open={popup} hidePopup={() => setPopup(false)}>
        {modal === 'codes' ?
          <div className="popup-inner popup-codes">
            {emptyVouchers ?
              <div className="no-vouchers">
                <h3>Brak dostępnych kodów</h3>
                <button className="btn" onClick={() => dispatch(requestVouchers())}>Generuj kody</button>
              </div> : null}
            {availableVouchers.map((item, key) => {
              const cl = item.redeemed ? 'active' : '';
              return (
                <div key={key} className={"code-item " + cl}>
                  <span className="nr">{(key + 1) + '.'}</span>
                  <div className="cupon-code">{item.ident}</div>
                  <Button type="redeem" action={() => dispatch(openRedeemPopup(action, item))}>Wydaj</Button>
                </div>
              )
            })
            }
          </div> :
          <div className="popup-inner">
            { action?.actionPoints.map((item, key) => {
              return (
                <div key={'location-' + key} onClick={() => { 
                 //setLocationKey(key);
                  setPopup(false); 
                }}><LocationView data={item} /></div>
              )
            }) }
          </div>
        }
      </BottomModal>
			<PopupModal open={codesPopup} hidePopup={() => setCodesPopup(false) }>
        <div className="popup-inner">
          <h3>{'Wydaję kod ' + selectedItem.ident + ':'}</h3>
          <div className="buttons">
            <Button type="empty" action={() => setCodesPopup(false) }>Anuluj</Button>
            <Button action={() => { setCodesPopup(false); dispatch(redeemVoucher(selectedItem.uuid)); }}>Potwierdź</Button>
          </div>
        </div>
      </PopupModal>
    </header>
  );
}
export default Header;