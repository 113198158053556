import React, { useEffect, useRef, useState } from "react";
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Modal from "../Modal";
import { useWebformStore } from "./store";
import BackArrow from "../../../img/arrow-back.svg"
import Flash from "../../../img/flash.svg"
import Accept from "../../../img/accept.svg"
import Reject from "../../../img/reject.svg"

export default NiceModal.create(({ photoRef }) => {
  const modal = useModal();
  const videoRef = useRef(null);
  const stripRef = useRef(null);
  const localStream = useRef(null)
  const [imgReady, setImgReady] = useState(false)
  const recieptURL = useWebformStore(state => state.recieptURL)
  const setRecieptURL = useWebformStore(state => state.setRecieptURL)

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({
        audio: false, video: {
          width: { min: 1024, ideal: 1600, max: 1920 },
          height: { min: 640, ideal: 1100, max: 1200 },
          facingMode: "environment"
        }
      })
      .then(stream => {
        localStream.current = stream
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
        console.log("hit")
      })
      .catch(err => {
        console.error("error:", err);
      });

    return () => {
      localStream.current.getTracks()[0].stop()
    }
  }, []);

  const paintToCanvas = () => {
    let video = videoRef.current;
    let photo = photoRef.current;
    let ctx = photo.getContext("2d");

    photo.width = 1100;
    photo.height = 1600;
    ctx.drawImage(video, 0, 0, 1100, 1600);
  };

  const takePhoto = () => {
    paintToCanvas()
    let photo = photoRef.current;
    let strip = stripRef.current;
    const data = photo.toDataURL("image/jpeg");
    const link = document.createElement("a");
    link.href = data;
    link.innerHTML = `<img style='height: ${videoRef.current?.clientHeight}px' src='${data}' alt='thumbnail'/>`;
    strip.replaceChild(link, strip.firstChild)
    setImgReady(true)
  };

  const clearPhotoCanvas = () => {
    let strip = stripRef.current;
    let photo = photoRef.current;
    const link = document.createElement("a");
    let ctx = photo.getContext("2d");
    ctx.fillStyle = "#AAA";
    ctx.fillRect(0, 0, 1100, 1600);
    const data = photo.toDataURL("image/jpeg");
    link.innerHTML = `<img src='${data}' alt='thumbnail'/>`;
    strip.replaceChild(link, strip.firstChild)
  }

  return (
    <Modal open={modal.visible} hidePopup={modal.hide} onAfterClose={modal.remove}>
      <div className="camera-output">
        <div className="top-menu">
          <button onClick={() => { modal.hide() }}>
            <img src={BackArrow} alt="" />
          </button>
          <button>
            <img src={Flash} alt="" />
          </button>
        </div>
        <div className="video-container">
          <video ref={videoRef} className={imgReady ? "hidden" : ""} />
          <div ref={stripRef} className={imgReady ? "" : "hidden"}>
            <img src={recieptURL ? recieptURL : ""} alt='' />
          </div>
          <canvas ref={photoRef} />
        </div>
        <div className="bottom-menu">
          <button onClick={() => { setRecieptURL(""); clearPhotoCanvas(); setImgReady(false) }}>
            <img src={Reject} alt="" />
          </button>
          <button>
            <div className="camera-button" onClick={() => { takePhoto() }}></div>
          </button>
          <button onClick={() => {
            setRecieptURL(stripRef.current.firstChild.href);
            modal.hide()
          }}>
            <img src={Accept} alt="" />
          </button>
        </div>
      </div>
    </Modal>
  )
})