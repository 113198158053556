import React from 'react'
import Modal from './Modal';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

export default NiceModal.create(({ error, setCapture }) => {
  const modal = useModal();

  return (
    <Modal open={modal.visible} hidePopup={modal.hide} onAfterClose={modal.remove}>
      <div className="default-popup">
        <h3 style={{textAlign: "center"}}>
          Wybierz sposób
        </h3>
        <button onClick={() => { modal.hide(); modal.remove(); setCapture(false) }} className={`btn white`}>
          Wybór mediów
        </button>
        <br/>
        <button onClick={() => { modal.hide(); modal.remove(); setCapture(true) }} className={`btn btn-dark`}>
          Aparat
        </button>
      </div>
    </Modal>
  )
});
