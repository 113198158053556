import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom'
import configureStore, { history } from './app/store'
import { appRoutes } from './app/routes';
import { loadState } from './app/localStorage';
//import * as serviceWorker from './serviceWorker';
import App from './app/containers/App';
import Page from './app/containers/Page';
import LoginPage from './app/components/LoginPage';
import ActionsPage from './app/components/ActionsPage';
import SingleActionPage from './app/components/SingleActionPage';
import ReportsPage from './app/components/ReportsPage';
import CalendarPage from './app/components/CalendarPage';
import SingleReportPage from './app/components/SingleReportPage';
import RemindPage from './app/components/RemindPage';
import UserDataPage from './app/components/UserDataPage';
import MGMPage from './app/components/MGMPage';
import Webform from './app/components/Webform/WebformNew';
import './scss/style.scss';
import NiceModal from '@ebay/nice-modal-react';
import "./app/components/Webform/modals"
import PhotosPage from './app/components/PhotosPage';

//serviceWorker.unregister();

const preloadedState = loadState();
const store = configureStore(preloadedState)

// const routes = appRoutes.map((item, i) => {
//   return (
//     <Route key={ item.route+"-route" } 
//       path={ item.route }
//       element={
//         (props) => (
//         	<Page>
// 	          <item.component
// 	            title={ item.title }
// 	            hideHeader={ item.hideHeader }
// 	            hideFooter={ item.hideFooter } {...props}
//               data={ data } />
//           </Page>
//         )
//       }/>
//   )
// })

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <NiceModal.Provider>
    <Provider store={store}>
      <BrowserRouter>
        <App>
          <Routes>
            <Route path="/" element={<Page><LoginPage hideFooter={true} /></Page>} />
            <Route path="/login" element={<Page><LoginPage hideFooter={true} /></Page>} />
            <Route path="/akcje" element={<Page><ActionsPage /></Page>} />
            <Route path="/akcja/:actionId" element={<Page><SingleActionPage /></Page>} />
            <Route path="/raporty" element={<Page><ReportsPage /></Page>} />
            <Route path="/raport/:reportId" element={<Page><SingleReportPage /></Page>} />
            <Route path="/kalendarz" element={<Page><CalendarPage /></Page>} />
            <Route path="/user-data" element={<Page><UserDataPage /></Page>} />
            <Route path="/remind" element={<Page><RemindPage hideFooter={true} /></Page>} />
            <Route path="/remind/code" element={<Page><RemindPage hideFooter={true} /></Page>} />
            <Route path="/mgm" element={<Page><MGMPage /></Page>} />
            <Route path="/webform" element={<Page><Webform /></Page>} />
            <Route path="/photos" element={<Page><PhotosPage /></Page>} />
            <Route path="/404" element={<Navigate to="/" />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </App>
      </BrowserRouter>
    </Provider>
  </NiceModal.Provider>
);