export const API_URL = process.env.REACT_APP_API_URL 

export function appFetch(method, api_url, endpoint, data, token, success, failed) {
    fetch(api_url+endpoint, {
        method: method,
        headers: (token ? {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + token
        } : {
            "Content-Type": "application/json; charset=utf-8"
        }),
        body: method === "GET" ? null : data ? JSON.stringify(data) : null
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
        success(result)
    }, (error) => {
        failed(error)
    })
}

export function appFetchBasic(method, api_url, endpoint, data, token, success, failed) {
    fetch(api_url+endpoint, {
        method: method,
        headers: (token ? {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Basic " + token
        } : {
            "Content-Type": "application/json; charset=utf-8"
        }),
        body: method === "GET" ? null : data ? JSON.stringify(data) : null
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
        success(result)
    }, (error) => {
        failed(error)
    })
}

export async function fetchData({endpoint, method="POST", payload, successCallback, errorCallback=false, fetchErrorCallback=false, shouldThrowError=true, setLoading=false, controller=false, token} = {}) {
    try {
      if(setLoading) {
        setLoading(true)
      }
      //   only on webform....
      const response = await fetch(process.env.REACT_APP_API_WEBFORM_URL + endpoint, {
        method: method,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": `Bearer ${token ?? ""}`,
        },
        body: JSON.stringify(payload),
        ...(controller && {signal: controller.signal})
      })
      const resObj = await response.json()
  
      if (resObj.status.success) {
        if(successCallback) {
          successCallback(resObj)
        }
      } else {
        if(errorCallback) {
          errorCallback(resObj)
        }
        if(shouldThrowError) {
        //   showErrorPopup(translateError(resObj.data.error), resObj.meta.ts)
        }
      }
    } catch (error) {
      console.log(error)
      if(fetchErrorCallback && error.name !== "AbortError") {
        fetchErrorCallback()
      }
      if(shouldThrowError && error.name !== "AbortError") {
        console.log(error.name)
        // showErrorPopup(translateError("generic"), "0000000")
      }
    }
    finally {
      if(setLoading) {
        setLoading(false)
      }
    }
  }