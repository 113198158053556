import React, { Component } from 'react';
import { ReactComponent as Marker } from '../../img/marker.svg'

class LocationView extends Component {

  render() {
    return (
      <div className="location">
        <div className="location-icon"><Marker/></div>
        <div className="location-data">
          <div className="location-id">{ "ID "+this.props.data.name }</div>
          <div className="location-date">{ this.props.data.address.streetAddress + ", " + this.props.data.address.postalCode + " " + this.props.data.address.cityName }</div>
          { /* <div className="location-time">{ this.props.data.timestart+" - "+this.props.data.timeend }</div> */ }
        </div>
      </div>
    );
  }
}

export default LocationView;