import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import { changePassWD } from '../actions/user';
import _ from 'lodash';
import PopupModal from './PopupModal';
import { ReactComponent as Arrow } from '../../img/arrow-back.svg'
import { getUser } from '../reducers/user';

function UserDataPage() {

  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const [passwordPopup, setPasswordPopup] = useState(false);
  const [password, setpassword] = useState('');

  const changePass = () => {
    const data = {
      user: {
        password: password
      }
    }

    dispatch(changePassWD(data))
    setPasswordPopup(false);
    setpassword('')
  }

  return (
    <section className="user-data">
      <NavLink to="/akcje" className="back"><Arrow /></NavLink>
      <h1 className="title">Twoje dane</h1>
      <div className="user-data-content">
        <h3>ID</h3>
        <p>{user.ident}</p>
        <h3>Login</h3>
        <p>{user.username}</p>
        <h3>Imię i nazwisko</h3>
        <p>{user.firstname + " " + user.lastname}</p>
        <h3>Terytoria</h3>
        <ul className="territories">
          {user.territories.map((item, i) => {
            return <li key={i}>{item.ident}</li>
          })}
        </ul>
        <h3>Adres e-mail</h3>
        <p>{user.emailAddress}</p>
        <h3>Numer telefonu</h3>
        <p>{user.mobilePhone}</p>
        <h3>Hasło</h3>
        <p className="pass"><span>********</span><button className="btn" onClick={() => setPasswordPopup(true)}>Zmień</button></p>
      </div>
      <PopupModal open={passwordPopup} hidePopup={() => setPasswordPopup(false) }>
        <div className="popup-inner">
          <h3>Zmiana hasła</h3>
          <input type="password" placeholder="Wpisz nowe hasło" value={password} onChange={(e) => setpassword(e.target.value)} />
          <button className="btn" onClick={() => changePass() }>Zmień hasło</button>
        </div>
      </PopupModal>
    </section>
  );
}

export default UserDataPage;