import { 
  REDEEM_VOUCHER,
  REQUEST_VOUCHERS,
  GET_BATCHES_SUCCESS,
  GET_VOUCHERS_SUCCESS,
  GET_VOUCHERS_REQUEST,
  SELECT_BATCH,
  ADD_TOAST
} from './types';
import { addToast } from './toast';
import { API_URL, appFetch } from '../helpers/fetch'
import { errors } from '../helpers/errors'
import moment from 'moment'

export function getBatches(eventID) {
  const data = {
    event: {
      uuid: eventID
    }
  }
  return (dispatch, getState) => { 
    const currentState = getState()
    appFetch("POST", API_URL, "/voucher/batches", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
        dispatch({ type: GET_BATCHES_SUCCESS, payload: result.data.batches });
      } else {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
      }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function selectBatch(batchID) {
  return (dispatch, getState) => { 
    dispatch({ type: SELECT_BATCH, payload: batchID });
    dispatch(getVouchers(batchID));
  }
}

export function getVouchers(batchID) {
  return (dispatch, getState) => { 
    const currentState = getState()
    const batch = batchID || currentState.vouchers.batchID
    const data = {
      batch: {
        uuid: batch
      }
    }
    dispatch({ type: GET_VOUCHERS_REQUEST, payload: [] });
    appFetch("POST", API_URL, "/voucher/vouchers", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: GET_VOUCHERS_SUCCESS, payload: result.data.vouchers });
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function requestVouchers(batchID) {
  return (dispatch, getState) => { 
    const currentState = getState()
    const batch = batchID || currentState.vouchers.batchID
    const data = {
      batch: {
        uuid: batch
      }
    }
    appFetch("POST", API_URL, "/voucher/top-up", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: REQUEST_VOUCHERS, payload: null });
          dispatch(getVouchers())
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}

export function redeemVoucher(codeID) {
  return (dispatch, getState) => { 
    const currentState = getState()
    const data = {
      "redemption": {
          "dateTime": moment().format("YYYY-MM-DD HH:mm:ss")
      },
      "batch": {
          "uuid": currentState.vouchers.batchID
      },
      "vouchers": [
          {
              "uuid": codeID
          }
      ]
  }
    appFetch("POST", API_URL, "/voucher/redeem", data, currentState.user.token ? currentState.user.token.uuid : false, (result) => {
      if(result.status && result.status.success) {
          dispatch({ type: REDEEM_VOUCHER, payload: null });
          dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Wydano kod." } });
          dispatch(getVouchers())
      } else {
          dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data && errors[result.data.error] ? errors[result.data.error] : result.data?.error ? result.data.error : errors.generic } });
      }
      }, (error) => {
        dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
}