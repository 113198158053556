import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from 'react-router-dom';
import _ from 'lodash';
import LocationView from './LocationView';
import { ReactComponent as Arrow } from '../../img/arrow-back.svg'
import moment from 'moment'
import PhotoModal from './PhotoModal';
import { selectReports } from '../reducers/reports';
import { getUser } from '../reducers/user';
import { leadingZero } from './Helpers';
import NiceModal from "@ebay/nice-modal-react";

function SingleReportPage() {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const reports = useSelector(selectReports);
  const { reportId } = useParams()
  const report = _.filter(reports.list, { uuid: reportId })[0];
  const [popup, setPopup]= useState(false);
  const [photoModal, setPhotoModal] = useState({open: false, imgUrl: ""});
  const [loading, setLoading] = useState(false);
  const [showReceipts, setShowReceipts] = useState(false);
  const [receiptsArray, setReceiptsArray] = useState([]);
  const [pollData, setPollData] = useState({})
  const status = {
    "finished": "Zakończona",
    "accepted": "Zaakceptowana"
  }

  const loadReceipts = async () => {
    setLoading(true);
    const response = await fetch(process.env.REACT_APP_API_URL + "/action/receipt/list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem('state'))?.user.token.uuid
        // 'X-Mode-Local': sessionStorage.getItem('devParams') ? true : false,
      },
      body: JSON.stringify({
        "action": {
          "uuid": reportId
        },
        "pagination": {
          "limit": 100,
          "offset": 0
        }
      })
    })
    const data = await response.json()
    if (data.status.success) {
      const newReceiptsArray = [...data?.data?.["receipts"]]

      // newReceiptsArray.forEach(e => e.img=this.loadImage(e.uuid))

      for (const receipt of newReceiptsArray) {
        receipt.img = await loadImage(receipt.uuid)
      }

      setLoading(false);
      setShowReceipts(true);
      setReceiptsArray(newReceiptsArray);
    }
  }

  async function loadImage (uuid) {
    const response = await fetch(process.env.REACT_APP_API_URL + "/action/receipt/serve", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem('state'))?.user.token.uuid
      },
      body: JSON.stringify({
        "receipt": {
          "uuid": uuid
        }
      })
    })
    const blob = await response.blob()
    return URL.createObjectURL(blob)
  }

  const getPoll = () => {
    let input = {
      "action": {
        "uuid": reportId || '1985859a-4cfc-4b8b-98e5-dda5b8f99353'
      }
    }

    fetch(process.env.REACT_APP_API_URL + "/action/file/list", {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + (localStorage.getItem("state") ? JSON.parse(localStorage.getItem("state"))?.user?.token?.uuid : "")
      },
      body: JSON.stringify(input)
    })
      .then((response) => {
        return response.json()
      })
      .then(
        (result) => {
          console.log(result)
          if (!result.status?.success) {
            NiceModal.show("photos-sent-modal", { error: { description: 'Wystąpił błąd podczas pobierania zdjęć', code: result?.meta?.ts ?? "" } });
          } else {
            setPollData(result.data)
          }
        },
        (error) => {
          console.log(error)
        }
      )
  }

  useEffect(() => {
    getPoll()
  }, [])

  const Image = (uuid) => {
    const [imgSrc, setImgSrc] = useState("")

    const getImg = () => { 
      fetch(process.env.REACT_APP_API_URL + "/action/file/serve", {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + (localStorage.getItem("state") ? JSON.parse(localStorage.getItem("state"))?.user?.token?.uuid : "")
      },
      body: JSON.stringify({
        "file": {
          "uuid": uuid
        }
      })
    })
    .then((response) => {
      return response.blob()
    })
    .then(
      (result) => {
        setImgSrc(URL.createObjectURL(result))
      },
      (error) => {
        console.log(error)
      }
    )
    }

    useEffect(() => {
      getImg()
    }, [])

    return imgSrc ? <img src={imgSrc} alt=""/> : <p className='loading'>Ładowanie...</p>
  }

  return (
    <section className="report">
      <NavLink to="/raporty" className="back"><Arrow /></NavLink>
      <h1 className="title">{report?.name}</h1>
      <p className="subtitle">{"ID AKCJI: " + report?.ident}</p>
      <div className="report-details">
        <div className="report-detail">
          <div className="detail-name">Data akcji</div>
          <div className="detail-value">{moment(report?.since.date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY')}</div>
        </div>
        <div className="report-detail">
          <div className="detail-name">Terytorium</div>
          <div className="detail-value">{report?.actionPoints[0].address.cityName}</div>
        </div>
        <div className="report-detail">
          <div className="detail-name">Hostessa</div>
          <div className="detail-value">{user.firstname + " " + user.lastname + ", ID " + user.ident}</div>
        </div>
        <div className="report-detail">
          <div className="detail-name">Godzina rozpoczęcia</div>
          <div className="detail-value">{moment(report?.since.date, 'YYYY-MM-DD HH:mm:ss').format("HH:mm:ss")}</div>
        </div>
        <div className="report-detail">
          <div className="detail-name">Godzina zakończenia</div>
          <div className="detail-value">{moment(report?.until.date, 'YYYY-MM-DD HH:mm:ss').format("HH:mm:ss")}</div>
        </div>
        <div className="report-detail">
          <div className="detail-name">Godzina zmiany lokalizacji</div>
          <div className="detail-value">{report?.status.user.lastStatusCreated != null ? moment(report?.status.user.lastStatusCreated.date).format("HH:mm:ss") : '-'}</div>
        </div>
        <div className="report-detail">
          <div className="detail-name">Czas trwania akcji</div>
          <div className="detail-value">{leadingZero(moment(report?.until.date).diff(moment(report?.since.date), 'hours')) + ":" + leadingZero(moment(report?.until.date).diff(moment(report?.since.date), 'minutes') % 60) + ":" + leadingZero(moment(report?.until.date).diff(moment(report?.since.date), 'seconds') % 60)}</div>
        </div>
        <div className="report-detail">
          <div className="detail-name">Status</div>
          <div className="detail-value">{status[report?.status.global.ident]}</div>
        </div>
      </div>
      <h3>Lokalizacje</h3>
      {report?.actionPoints.map((item, key) => {
        return <LocationView key={key} data={item} />
      })}
      {showReceipts ?
        <div className="receipts">
          { receiptsArray?.length > 0 && receiptsArray != undefined ? 
            receiptsArray?.map((element, index) => (
              <div className="receipt" key={index} onClick={() => {
                setPhotoModal({
                  open: true,
                  imgUrl: element.img
                })
              }}>
                <div className="image">
                  <img src={element.img} alt="" />
                </div>
                <div className="text">
                  <p>{element.device}</p>
                </div>
              </div>
            )) 
          : <p>Brak paragonów dla tego raportu.</p>
          }   
        </div>
        :
        <button className='btn' onClick={() => { loadReceipts() }}>{loading ? "Ładowanie" : "Pokaż paragony"}</button>
      }
      <PhotoModal open={photoModal.open} url={photoModal.imgUrl} close={() => setPhotoModal({...photoModal, open: false}) }/>
      { pollData?.files?.length > 0 && <>
        <h3>Zdjęcia produktów</h3>
        <ul className='poll-images-list'>
          { pollData?.files?.map((image, i) => {
            return (
              <li key={i}>
                <Image uuid={image.uuid}/>
                <p>{ image.ident === "start" ? "Zdjęcie przed ułożeniem ekspozycji" : image.ident === "end" ? "Zdjęcie po ułożeniu ekspozycji" : "" }</p>
              </li>
            )
          }) }
        </ul>
      </> }
      { pollData?.poll?.chechboxes && <>
        <h3>Odpowiedzi z ankiety</h3>
        <div className='poll-data'>
          <p className='poll-question'>1. Czy nasze produkty są wyeksponowane na meblu?</p>
          <p className='poll-answer'><strong>{pollData?.poll?.chechboxes[0] === "tak" ? "TAK" : "NIE"}</strong></p>
        </div>
        <div className='poll-data'>
          <p className='poll-question'>2. Czy planogram został przygotowany?</p>
          <p className='poll-answer'><strong>{pollData?.poll?.chechboxes[1] === "tak" ? "TAK" : "NIE"}</strong></p>
        </div>
        <div className='poll-data'>
          <p className='poll-question'>3. Czy przeprowadzono szkolenie dla detalisty?</p>
          <p className='poll-answer'><strong>{pollData?.poll?.chechboxes[2] === "tak" ? "TAK" : "NIE"}</strong></p>
        </div>
        <div className='poll-data'>
          <p className='poll-question'>4. Komentarz hostessy</p>
          <p className='poll-answer'><strong>{pollData?.poll?.text || "Brak"}</strong></p>
        </div>
      </> }
    </section>
  );
}

export default SingleReportPage;