import React, { Component, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import _ from 'lodash';
import BaseComponent from "../components/Helpers";
import Header from "../components/Header"
import { saveDimensionData } from '../actions/screen';
import { checkSession} from '../actions/user';
import { useLocation, useNavigate, withRouter } from 'react-router-dom';
import Toasts from '../components/toasts/Toasts';
import Button from '../components/Button';
import DATA from '../data/data.json';
import { ReactComponent as ColorLine } from '../../img/color_line.svg'

function App({children}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(()=>{
    dispatch(checkSession(navigate));
  },[])

  return (
    <div className="app">
      <ColorLine className="color-line" preserveAspectRatio="none"/>
      { location.pathname !== "/" && location.pathname.indexOf('/remind') < 0 ? <Header/> : null }
      {children}
      <Toasts />
      <div className="page-preloader hide"><span></span></div>
    </div>
  )
}

export default App;